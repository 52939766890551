.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width:calc(100%);
  padding:0;
  margin:0;
  position: relative;
  background: #111;
  color:#ECECEC;

  .footer-inner-mobile {
    display: none;
  }

  .footer-inner {
    padding:0;
    margin:0;
    width: calc(100% - 5.55vw);
    /* margin-left:2.77vw; */
    display: flex;
    flex-direction: column;

    color:#ECECEC;
    min-height: 80px;

    margin-top:2.77vw;

    .footer-headings{
      display: flex;
      flex-direction: row;
      border-bottom: solid 1px #ECECEC;

      .heading {
        color:#ECECEC;
        font-family: "GTPressuraLCGVMono-Regular";
        font-size: .8333vw;
        font-style: normal;
        font-weight: 400;
        line-height:100%;
        letter-spacing: -0.00833vw;
        text-transform: uppercase;
        padding-bottom:.486vw;
        padding-left:.833vw;

        &.w-20 {
          width:calc(20% - .833vw);
        }
        &.w-40 {
          width:calc(40% - .833vw);
        }
      }
      
    }

    .footer-content{
      display: flex;
      flex-direction: row;
      padding-bottom:6.25vw;
      .content {
        color:#ECECEC;
        font-family: "HTQ-Waldenburg-Halbschmal";
        font-size: 1.111vw;
        font-style: normal;
        font-weight: 400;
        line-height:125%;
        padding-top:.486vw;
        padding-left:.833vw;
        padding-bottom:.833vw;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: start;
        
        &.w-20 {
          width:calc(20% - .833vw);
        }
        &.w-40 {
          width:calc(40% - .833vw);
        }

        border-left: solid 1px #ECECEC;

        .footer-link {
          background:none;
          border:none;
          margin:0;
          padding:0;
          color:#ECECEC;
          font-family: "HTQ-Waldenburg-Halbschmal";
          font-size: 1.111vw;
          font-style: normal;
          font-weight: 400;
          line-height:125%;
          text-decoration: underline;
          cursor: pointer;
          
        }
      }
      
    }
  }

  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {

    .footer-inner {
      display: none;
    }
  
    .footer-inner-mobile {
      padding:0;
      margin:0;
      width: calc(100% - 8vw);
      /* margin-left:2.77vw; */
      display: flex;
      flex-direction: column;
  
      color:#ECECEC;
      // min-height: 80px;
  
      margin-top:8vw;
  
      .footer-headings{
        display: flex;
        flex-direction: row;
        border-bottom: solid 1px #ECECEC;
  
        .heading {
          color:#ECECEC;
          font-family: "GTPressuraLCGVMono-Regular";
          font-size: 3vw;
          font-style: normal;
          font-weight: 400;
          line-height:100%;
          letter-spacing: -0.03vw;
          text-transform: uppercase;
          padding-bottom:2vw;
          padding-left:3vw;
  
          &.w-20 {
            width:calc(20% - 3vw);
          }
          &.w-40 {
            width:calc(40% - 3vw);
          }
          &.mw-50 {
            width:calc(50% - 3vw);
          }
          &.mw-100 {
            width:calc(100% - 3vw);
          }
        }
        
      }
  
      .footer-content{
        display: flex;
        flex-direction: row;
        padding-bottom:6.25vw;
        .content {
          color:#ECECEC;
          font-family: "HTQ-Waldenburg-Halbschmal";
          font-size: 4vw;
          font-style: normal;
          font-weight: 400;
          line-height:125%;
          padding-top:2vw;
          padding-left:3vw;
          padding-bottom:3vw;
          display: flex;
          flex-direction: column;
          align-items: start;
          justify-content: start;
          
          &.w-20 {
            width:calc(20% - 3vw);
          }
          &.w-40 {
            width:calc(40% - 3vw);
          }
          &.mw-50 {
            width:calc(50% - 3vw);
          }
          &.mw-100 {
            width:calc(100% - 3vw);
          }
  
          border-left: solid 1px #ECECEC;
  
          .footer-link {
            background:none;
            border:none;
            text-align: left;
            margin:0;
            padding:0;
            color:#ECECEC;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-size: 4vw;
            font-style: normal;
            font-weight: 400;
            line-height:145%;
            text-decoration: underline;
            cursor: pointer;
            
          }
        }
        
      }
    }

  }


 
}


