.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width:calc(100%);
  min-height: 100%;
  padding:0;
  margin:0;
  position: relative;
  background-color:#DBDCD5; 
  background-image:url("../../assets/images/noise.png"); 
  background-repeat: repeat; 
  background-size: auto;


  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
   
  }


 
}


