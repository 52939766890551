.story {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width:calc(100%);
  padding:0;
  margin:0;
  position: relative;

  .mobile-mini-nav { display: none;}

  .story-left {
    height: 100vh;
    box-sizing: border-box;
    width:50%;
    background-color:#CDD8E4;
    background-image:url("../../assets/images/noise.png"); 
    background-repeat: repeat; 
    background-size: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    top:0;
    position: sticky;
    

    .pill-canvas {
      width:50vw;
      height: 40vw;
      margin-top:0vh;
      margin-bottom:-20vh;
      position: relative;
      background:transparent;
      // margin-top:8vh;
      // background:red;
      .loading-wrapper {
        // width:100% !important;
        // height: 100% !important;
        // position: absolute;
        // top:1.5vw !important;
        // left:0;
        transform: unset !important;
        width:100% !important;
        height: 100% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        position: absolute;
        top:1vw !important;
        left:0;
      }
  
      .lf-player-container {
        width:100%;
        height: 100%;
        filter:grayscale(1);
      }
      
    }

    .info {

      

      display: flex;
      position: relative;

      flex-direction: column;
      width: calc(100% - 3.33vw);

      box-sizing: border-box; 
      margin-top:10vw;
      margin-bottom:1.2vw;
      .row {
        display: flex;
        flex-direction: row;
        box-sizing: border-box; 
        &.title-row {
          border-bottom: 1px solid #111;
          
          padding-bottom: .4vw;

          &.title-row-mobile {
            display: none;
          } 

          .info-title {
            color: var(--Primary-Black, #111);
            leading-trim: both;
            text-edge: cap;
            /* GT Pressura/12pt */
            font-family: "GTPressuraLCGVMono-Regular";
            font-size: .8333vw;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: -.00833vw;
            text-transform: uppercase;
            padding-left:.833vw;
            box-sizing: border-box; 
          }
          
        }

        &.content-row {
          
          margin-bottom:1.2vw;
          box-sizing: border-box; 

          &.content-row-mobile {
            display: none;
          } 


          .info-content {
            color: #111;
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-size: 1.11vw;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            border-left:1px solid #111;
            padding-top:.833vw;
            padding-left:.833vw;
            box-sizing: border-box; 
          }
          
          
        }

        

        &.row-0 {
          .info-title, .info-content {
            width:100%;
            box-sizing: border-box; 
          }
        }

        &.row-1 {
          .info-title, .info-content {
            width:50%;
            box-sizing: border-box; 
          }
        }

        &.row-2 {
          .info-title, .info-content {
            width:calc(25%);
            box-sizing: border-box; 
            
          }
          .cell-2 {
            width:calc(50% );
            box-sizing: border-box; 
          }
        }

      }

    }

    .button-row {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: calc(100%);
      margin-top:1.6vw;

      .overview-button {
        color:#111;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "HTQ-Waldenburg-FettSchmal";
        font-size:1.11vw;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;

        border-radius: 1.4vw;
        border: 1px solid #111;
        background:transparent;
        padding:.833vw;
        padding-left:1.4vw;
        padding-right:1.4vw;
        cursor: pointer;
        transition: all .3s ease-out;

        &:hover {
          color:#D0E4CD;
          background:#111;
        }

      }

      .button-row-right {
        display: flex;
        flex-direction: row;
        gap:.55vw;
        align-items: center;
        justify-content: center;

        
      }

      .next-button {
        color:#111;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "HTQ-Waldenburg-FettSchmal";
        font-size:1.11vw;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;

        border-radius:8.33vw;
        border: 1px solid #111;
        background:transparent;
        padding:.833vw;
        padding-left:1.4vw;
        padding-right:1.4vw;
        cursor: pointer;

        svg {
          height: .7vw;
        }

        transition: all .3s ease-out;

        &:hover {
          color:#D0E4CD;
          background:#111;

          svg {
            * {
              stroke:#D0E4CD !important;
            }
          }
        }

      }

      .back-button {
        color:#111;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "HTQ-Waldenburg-FettSchmal";
        font-size:1.11vw;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        text-transform: uppercase;

        border-radius:8.33vw;
        border: 1px solid #111;
        background:transparent;
        padding:.833vw;
        padding-left:1.4vw;
        padding-right:1.4vw;
        cursor: pointer;

        svg {
          height: .7vw;
          transform: rotate(180deg);
        }
        transition: all .3s ease-out;
        &:hover {
          color:#D0E4CD;
          background:#111;
          svg {
            * {
              stroke:#D0E4CD !important;
            }
          }
        }
      }

      .back-button-mobile {
        display: none;
      }

    }

  }

  .story-right {
    min-height: 200vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-image:url("../../assets/images/noise.png"); 
    background-repeat: repeat; 
    background-size: auto;

    .story-titles {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top:20vh;

      .title {
        margin:0;
        padding:0;
        opacity:0;
        &.title-1, &.title-3  {
          text-align: center;
          leading-trim: both;
          text-edge: cap;
          -webkit-text-stroke: 1.5px #fff;
          text-stroke: 1.5px #fff;
          color: transparent;
          font-family: "HTQ-Waldenburg-FettSchmal";
          font-size: 9.7vw;
          font-style: normal;
          font-weight: 700;
          line-height:  85.714%;
          text-transform: uppercase;
        }
        &.title-2 {
          color:  #fff;
          leading-trim: both;
          text-edge: cap;
          font-family: "HTQ-Waldenburg-FettSchmal";
          font-size: 9.7vw;
          font-style: normal;
          font-weight: 700;
          line-height:  85.714%;
          text-transform: uppercase;
        }
      }
    }

    .audio-player-container {
      margin-top:7.2vw;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .script {
      margin-top:7.2vw;
      width:calc(100% - 10.2vw );
      margin-left:8.33vw;
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      .text {
        leading-trim: both;
        text-edge: cap;
        /* GT Pressura/20pt */
        font-family: "GTPressuraLCGVMono-Light";
        font-size: 1.3888vw;
        font-style: normal;
        font-weight: 300;
        line-height: 160%;
        text-indent: 4.8vw; 
        position: relative;
        

        >.small-title {
          position: absolute;
          left:-10.2vw;
          font-family: "GTPressuraLCGVMono-Regular";
          font-size:.83vw;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          letter-spacing:-.0083vw;
          text-transform: uppercase;
          top:.48vw;
        }

        &.pill-title{
          text-indent: 4.8vw;
          margin:0;
          padding:0;
        }
        &.callout {
          text-indent: 0;
          font-family: "GTPressuraLCGVMono-Regular";
          font-size: 4.4444vw;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
        }

      }

      .end  {
        margin:0;
        padding:0;
        align-self: center;
        font-family: "GTPressuraLCGVMono-Regular";
        font-size:.83vw;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing:-.0083vw;
        text-transform: uppercase;
        margin-top:2.4vw;
        margin-bottom:8.33vw;
        margin-left:-3.33vw;
      }
      
    }

    .bottom-block{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width:calc(100% - 3.33vw);
      margin:0;
      padding:0;
      margin-bottom:1.66vw;

      .bottom-block-title {
        width:100%;
        box-sizing:border-box;
        padding:1.66vw;
        padding-top:.55vw;
        padding-bottom: .55vw;
        leading-trim: both;
        text-edge: cap;
        /* GT Pressura/12pt */
        font-family: "GTPressuraLCGVMono-Regular";
        font-size: .833vw;
        font-style: normal;
        font-weight: 400;
        line-height:  100%;
        letter-spacing: -0.0083vw;
        text-transform: uppercase;
      }

      .bottom-block-text{
        leading-trim: both;
        text-edge: cap;
        padding:1.66vw;
        font-family: "HTQ-Waldenburg-Halbschmal";
        font-size:1.66vw;
        font-style: normal;
        font-weight: 400;
        line-height: 133.333%;
        letter-spacing: -0.0166vw;
        
        a {
          leading-trim: both;
          text-edge: cap;
          font-family: "HTQ-Waldenburg-Halbschmal";
          font-size:1.66vw;
          font-style: normal;
          font-weight: 400;
          line-height: 133.333%;
          letter-spacing: -0.0166vw;
        }
      }
      .warning{
        leading-trim: both;
        text-edge: cap;
        font-family: "HTQ-Waldenburg-Halbschmal";
        font-size: 1.11vw;
        font-style: normal;
        font-weight: 400;
        line-height:  125%;
        align-self:flex-start;
        justify-self:flex-start;
      }
      
    }

    .how-to-quit-button {
      margin-top:4.8vw;
      margin-bottom: 8.4vw;
      border-radius: 1.4vw;
      background:  #F8401C;
      color: #111;
      border:none;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: "HTQ-Waldenburg-FettSchmal";
      font-size: 1.11vw;
      font-style: normal;
      font-weight: 700;
      line-height:125%;
      width:14.4vw;
      height:2.77vw ;
      transition: all .3s ease-out;
      cursor: pointer;
      &:hover {
        color:  #F8401C;
        background: #111;
      }
    }

  }

  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width:calc(100%);
    padding:0;
    margin:0;
    position: relative;

    .mobile-mini-nav {
      display: flex;
      position: fixed;
      top:0;
      left:0;
      width:100%;
      z-index: 10;
      padding-top:16vw;
      opacity: .2;
      // z-index: -1;

      .pill-image { 
        height: 12.468vw;
      }

      .button-row {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 8vw);
        margin-left: auto;
        margin-right: auto;
        margin-top:8vw;
        margin-bottom:7vw;

        

        .button-row-right {
          display: flex;
          flex-direction: row;
          gap:.55vw;
          align-items: center;
          justify-content: center;
        }

        .next-button {
          margin:0;
          padding:0;
          box-sizing: border-box;
          width:15vw;
          height: 12.468vw;
          border-radius:30.5vw;
          border: 1px solid #111;
          background:transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg {
            width:5vw;
            height: auto;
          }

        }


        .back-button-mobile{
          margin:0;
          padding:0;
          box-sizing: border-box;
          width:15vw;
          height: 12.468vw;
          border-radius:30.5vw;
          border: 1px solid #111;
          background:transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg {
            width:5vw;
            height: auto;
            transform: rotate(180deg);
          }
        }

        .back-button {
          display: none;

        }

      }
    }

    .story-left {
      height: auto;
      box-sizing: border-box;
      width:100%;
      background-color:#CDD8E4;
      background-image:url("../../assets/images/noise.png"); 
      background-repeat: repeat; 
      background-size: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      top:0;
      position: relative;
      

      .pill-canvas {
        width:100vw;
        height:100vw;
        margin-top:10vh;
        margin-bottom:-10vh;
        position: relative;
        background:transparent;
        touch-action: none;
        // margin-top:8vh;
        // background:red;
        .loading-wrapper {
          width:100% !important;
          height: 100% !important;
          position: absolute;
          top:1.5vw !important;
          left:0;
          transform: unset !important;
        }
    
        .lf-player-container {
          width:100%;
          height: 100%;
          filter:grayscale(1);
        }
        
      }

      .info {
        display: flex;
        position: relative;

        flex-direction: column;
        width: calc(100% - 8vw);

        box-sizing: border-box; 
        margin-top:10vw;

        

        

        .row {
          display: flex;
          flex-direction: row;
          box-sizing: border-box; 

          

          &.title-row {
            display: flex;
            border-bottom: 1px solid #111;
            padding-bottom: 2vw;

            &.desktop-only {
              display: none;
            }

            &.title-row-mobile {
              display: flex;
            }

            .info-title {
              color: var(--Primary-Black, #111);
              leading-trim: both;
              text-edge: cap;
              /* GT Pressura/12pt */
              font-family: "GTPressuraLCGVMono-Regular";
              font-size: 3vw;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
              letter-spacing: -.00833vw;
              text-transform: uppercase;
              padding-left:3vw;
              box-sizing: border-box; 
            }
            
          }

          &.content-row {
            
            margin-bottom:6vw;
            box-sizing: border-box; 

            &.desktop-only {
              display: none;
            }

            &.content-row-mobile {
              display: flex;
            }

            .info-content {
              color: #111;
              leading-trim: both;
              text-edge: cap;
              font-family: "HTQ-Waldenburg-Halbschmal";
              font-size: 4vw;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
              border-left:1px solid #111;
              padding-top:3vw;
              padding-left:3vw;
              box-sizing: border-box; 
            }
            
            
          }

          

          &.row-0 {
            .info-title, .info-content {
              width:100%;
              box-sizing: border-box; 
            }
          }

          &.row-1 {
            .info-title, .info-content {
              width:50%;
              box-sizing: border-box; 
            }
          }

          &.row-2 {
            .info-title, .info-content {
              width:calc(50%);
              box-sizing: border-box; 
              
            }
            .cell-2 {
              width:calc(50% );
              box-sizing: border-box; 
            }
          }

          &.row-3 {
            .info-title, .info-content {
              width:100%;
              box-sizing: border-box; 
            }
          }

        }

      }

      .button-row {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: calc(100%);
        margin-top:8vw;

        margin-bottom:11vw;

        .overview-button {
          color:#111;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "HTQ-Waldenburg-FettSchmal";
          font-size:6.1vw;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
          text-transform: uppercase;
          border-radius:20.35vw;
          border: 1px solid #111;
          background:transparent;
          padding:0;
          cursor: pointer;
          box-sizing: border-box;
          width:39.44vw;
          height: 12.468vw;
          
        }

        .button-row-right {
          display: flex;
          flex-direction: row;
          gap:.55vw;
          align-items: center;
          justify-content: center;
        }

        .next-button {
          margin:0;
          padding:0;
          box-sizing: border-box;
          width:15vw;
          height: 12.468vw;
          border-radius:30.5vw;
          border: 1px solid #111;
          background:transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg {
            width:5vw;
            height: auto;
          }

        }


        .back-button-mobile{
          margin:0;
          padding:0;
          box-sizing: border-box;
          width:15vw;
          height: 12.468vw;
          border-radius:30.5vw;
          border: 1px solid #111;
          background:transparent;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg {
            width:5vw;
            height: auto;
            transform: rotate(180deg);
          }
        }

        .back-button {
          display: none;

        }

      }

    }

    .story-right {
      min-height: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      background-image:url("../../assets/images/noise.png"); 
      background-repeat: repeat; 
      background-size: auto;

      .story-titles {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top:24vw;

        .title {
          margin:0;
          padding:0;
          &.title-1, &.title-3  {
            text-align: center;
            leading-trim: both;
            text-edge: cap;
            -webkit-text-stroke: 1px #fff;
            text-stroke: 1px #fff;
            color: transparent;
            font-family: "HTQ-Waldenburg-FettSchmal";
            font-size: 20.35vw;
            font-style: normal;
            font-weight: 700;
            line-height:  85.714%;
            text-transform: uppercase;
          }
          &.title-2 {
            color:  #fff;
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-FettSchmal";
            font-size: 20.35vw;
            font-style: normal;
            font-weight: 700;
            line-height:  85.714%;
            text-transform: uppercase;
          }
        }
      }

      .audio-player-container {
        margin-top:8vw;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .script {
        margin-top:16vw;
        width:calc(100% - 20vw );
        margin-left:16vw;
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        .text {
          font-family: "GTPressuraLCGVMono-Light";
          font-size: 5vw;
          font-style: normal;
          font-weight: 300;
          line-height: 160%;
          text-indent: 10vw; 
          position: relative;
          

          >.small-title {
            position: absolute;
            left:-20vw;
            font-family: "GTPressuraLCGVMono-Regular";
            font-size:3vw;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            letter-spacing:-.0083vw;
            text-transform: uppercase;
            top:2vw;
          }

          &.pill-title{
            text-indent: 10vw;
            margin:0;
            padding:0;
          }
          &.callout {
            text-indent: 0;
            font-family: "GTPressuraLCGVMono-Regular";
            font-size: 13vw;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            margin-left:-12vw;
            word-wrap: break-word;
          }

        }

        .end  {
          margin:0;
          padding:0;
          align-self: center;
          font-family: "GTPressuraLCGVMono-Regular";
          font-size:3vw;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          letter-spacing:-.0083vw;
          text-transform: uppercase;
          margin-top:2.4vw;
          margin-bottom:16vw;
          margin-left:-12vw;
        }
        
      }

      .bottom-block{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width:calc(100% - 8vw);
        margin:0;
        padding:0;
        margin-bottom:6vw;

        .bottom-block-title {
          width:100%;
          box-sizing:border-box;
          padding:6vw;
          padding-top:2.2vw;
          padding-bottom: 2.2vw;
          leading-trim: both;
          text-edge: cap;
          /* GT Pressura/12pt */
          font-family: "GTPressuraLCGVMono-Regular";
          font-size: 3vw;
          font-style: normal;
          font-weight: 400;
          line-height:  100%;
          letter-spacing: -0.0083vw;
          text-transform: uppercase;
        }

        .bottom-block-text{
          leading-trim: both;
          text-edge: cap;
          padding:6vw;
          font-family: "HTQ-Waldenburg-Halbschmal";
          font-size:5vw;
          font-style: normal;
          font-weight: 400;
          line-height: 133.333%;
          letter-spacing: -0.05vw;
          
          a {
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-size:5vw;
            font-style: normal;
            font-weight: 400;
            line-height: 133.333%;
            letter-spacing: -0.05vw;
          }
        }
        .warning{
          leading-trim: both;
          text-edge: cap;
          font-family: "HTQ-Waldenburg-Halbschmal";
          font-size: 4vw;
          font-style: normal;
          font-weight: 400;
          line-height:  125%;
          align-self:flex-start;
          justify-self:flex-start;
        }
        
      }

      .how-to-quit-button {
        margin-top:4.8vw;
        margin-bottom: 8.4vw;
        border-radius: 20.35vw;
        background:  #F8401C;
        color: #111;
        border:none;
        text-align: center;
        leading-trim: both;
        text-edge: cap;
        font-family: "HTQ-Waldenburg-FettSchmal";
        font-size: 6vw;
        font-style: normal;
        font-weight: 700;
        line-height:125%;
        width:calc(100% - 8vw);
        height:12.7vw ;
        transition: all .3s ease-out;
        cursor: pointer;
        &:hover {
          color:  #F8401C;
          background: #111;
        }
      }

    }
  }


 
}


