.menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;

  padding:0;
  margin:0;
  position: relative;
  pointer-events: none;

  .menu-button {
    background:none;
    border: none;
    cursor: pointer;
    color:#111;
    margin:0;
    padding:0;
    pointer-events: all;

    >svg{
      height: 2.77vw;
      width: auto;
      transition: color .3s ease-out;
      &:hover {
        color:#F8401C;
      }
    }
  }

  .menu-buttons {
    display: flex;
    flex-direction: row;
    gap:.277vw;
    align-items: center;
    justify-content: flex-start;
    margin:0;
    padding:0;
    margin-left:.277vw;
    .menu-button {
      >svg{
        height: 2.77vw;
        width: auto;
        transition: color .3s ease-out;
        &:hover {
          color:#F8401C;
        }
      }
      opacity: 0;
      visibility: hidden;
    }
   
  }

  .mobile-menu-buttons {
    display: none;
  }


  .title {
    font-family: "HTQ-Waldenburg-FettSchmal";
    font-size: 9.166vw;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -.09166vw;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 1023px) {

  }

  @media only screen and (max-width: 767px) {
    .menu-button {
     
      position: relative;
      z-index: 100;
      >svg{
        height: 11.7vw;
      }
    }

    .menu-buttons {
      display:none;
    }

    .mobile-menu-buttons {

      display: flex;
      flex-direction: column;
      gap:2vw;
      align-items: center;
      justify-content: center;
      margin:0;
      padding:0;
      margin-left:0;
      position: absolute;
     
      // backdrop-filter: blur(10px) ;
      width:100vw;
      height: 100vh;
      top:-15vw;
      padding-top:15vw;
      left:-4vw;

      transition: all .5s ease-out;

      &.active {
        background-color:#DBDCD5; 
        background-image:url("../../assets/images/noise.png"); 
      }

      .menu-button {
        // height: 11.7vw;
        // width: auto;
        >svg{
          height: 22vw;
          width: auto;
          transition: color .3s ease-out;
          &:hover {
            color:#F8401C;
          }
        }
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }
     
    }
  }




 
}


