.audio-player{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: calc(100% - 4.8vw);
  padding:0;
  margin:0;
  position: relative;
  color:#ECECEC;
  box-sizing: border-box;

  .progress-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
  }

  .play-button {
    width: 2.77vw;
    height: 2.77vw;
    flex-shrink: 0;
    cursor: pointer;
    background:transparent;
    border: none;
    color:#f8401c;
    transition: all .3s ease-out;
    padding:0;
    margin:0;
    scale:1.0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;
    svg {
      padding:0;
      margin:0;
      width: 100%;
      pointer-events: none;

    }
    &:hover {
      scale:1.1;
    }
    
  }

  .play-time {
    margin-left:3vw;
    color:  #F8401C;
    leading-trim: both;
    text-edge: cap;
    font-family: "GTPressuraLCGVMono-Regular";
    font-size: .83vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.0083vw;
    text-transform: uppercase;
    flex-shrink: 0;
  }

  .total-time {
    margin-left:3vw;
    color: #F8401C;
    leading-trim: both;
    text-edge: cap;
    font-family: "GTPressuraLCGVMono-Regular";
    font-size: .83vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.0083vw;
    text-transform: uppercase;
    flex-shrink: 0;
  }

  .progress {
   
    margin-left:3vw;
    width: 100%;
    height:3.0vw;
   
    
  }
 

  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100% - 4.8vw);
    padding:0;
    margin:0;
    position: relative;
    color:#ECECEC;
    box-sizing: border-box;

  
    .play-button {
      width: 10.1vw;
      height:10.1vw;
      flex-shrink: 0;
      cursor: pointer;
      background:transparent;
      border: none;
      color:#f8401c;
      transition: all .3s ease-out;
      padding:0;
      margin:0;
      scale:1.0;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: all;
      margin-bottom:4vw;
      svg {
        padding:0;
        margin:0;
        width: 100%;
        pointer-events: none;
  
      }
      &:hover {
        scale:1.1;
      }
      
    }
  
    .play-time {
      margin-left:3vw;
      color:  #F8401C;
      leading-trim: both;
      text-edge: cap;
      font-family: "GTPressuraLCGVMono-Regular";
      font-size: 3vw;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.0083vw;
      text-transform: uppercase;
      flex-shrink: 0;
    }
  
    .total-time {
      margin-left:3vw;
      color: #F8401C;
      leading-trim: both;
      text-edge: cap;
      font-family: "GTPressuraLCGVMono-Regular";
      font-size: 3vw;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.0083vw;
      text-transform: uppercase;
      flex-shrink: 0;
    }
  
    .progress {
     
      margin-left:3vw;
      width: 100%;
      height:5.8vw;
      
      
    }
   

  }

 
}


