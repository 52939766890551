/* fonts */

/* TODO: Replace site fonts when we have them */



@font-face {
  font-family: "HTQ-Waldenburg-FettSchmal";
  src: url("./fonts/HTQ-Waldenburg-FettSchmal.woff2") format("woff2"),
    url("./fonts/HTQ-Waldenburg-FettSchmal.woff") format("woff");
  font-display: swap;
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-family: "HTQ-Waldenburg-Halbschmal";
  src: url("./fonts/HTQ-Waldenburg-Halbschmal.woff2") format("woff2"),
    url("./fonts/HTQ-Waldenburg-Halbschmal.woff") format("woff");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: "GTPressuraLCGVMono-Light";
  src: url("./fonts/GTPressuraLCGVMono-Light.woff2") format("woff2"),
    url("./fonts/GTPressuraLCGVMono-Light.woff") format("woff");
  font-display: swap;
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "GTPressuraLCGVMono-Regular";
  src: url("./fonts/GTPressuraLCGVMono-Regular.woff2") format("woff2"),
    url("./fonts/GTPressuraLCGVMono-Regular.woff") format("woff");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}



/* global */

body,html {
  width:100%;
  height: 100%;
  margin:0;
  padding:0;
  font-family: "HTQ-Waldenburg-Halbschmal";
  color: #111;
  background-color:#111;
  overscroll-behavior-y: none;
  /* background-color:#DBDCD5; background-image:url("./assets/images/noise.png"); background-repeat: repeat; background-size: auto; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "HTQ-Waldenburg-FettSchmal";
  color: #111;
}

button, a, p, span, div{
  font-family: "HTQ-Waldenburg-Halbschmal";
  color: #111;
}



#root{
  height: 100%;
  width: 100%;
  margin:0;
  padding: 0;
}

/* scroll bars */

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 0px rgba(0,0,0,0.3);
	background-color: transparent;
}

::-webkit-scrollbar
{
	width: 0px;
  height: 0;
	background-color: transparent;
}

::-webkit-scrollbar-thumb
{
	background-color: #111;
}


/* Global Colors */
