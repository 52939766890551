.stories-grid {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  min-height: 60vh;
  width:calc(100%);
  padding:0;
  margin:0;
  position: relative;
  color:#ECECEC;
  margin-top:3.33vw;
  

  .stories-grid-inner {
    width:calc(100% - 5.55vw);
    display: flex;

    &.grid-style {
      flex-direction: row;
      flex-wrap: wrap;
      .headings {
        display: none;
      }
    }

    &.list-style {
      flex-direction: column;
      flex-wrap:nowrap;


      .headings {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
      
        font-family: "GTPressuraLCGVMono-Regular";
        font-size: .833vw;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -0.0083vw;
        text-transform: uppercase;
        padding-bottom:.55vw;
        
        .heading {
          font-family: "GTPressuraLCGVMono-Regular";
          padding-left:.55vw;
          &.col-0 {
            width:15.15vw;
            flex-shrink: 0;
          }
          &.col-1 {
            width:30.9058vw;
            flex-shrink: 0;
          }
          &.col-2 {
            width:23.05vw;
            width:39.3vw;
            flex-shrink: 0;
          }
          &.col-3 {
            width:15.25vw;
          }
        }
      }

      .story-block {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border-top: 1px solid #111;
        padding-top:2vw;
        padding-bottom:2vw;
        height: 100%;
        pointer-events: all;
        cursor: pointer;


        // background: linear-gradient( to bottom, black 50%, transparent 0);
        background-size: 100% 200%;
        background-position: 0 100%;

        .story-cell {
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          width:100%; 
          height: 100%;
          padding-left:.55vw;
          pointer-events: none;

          &.cell-0{
            width:15.7vw;
            height:100%;
            >img {
              height: 4.4vw;
              width: auto;
            }
          }

          &.cell-1 {
            margin:0;
            padding:0;
            width:31.4558vw;
            
            .name-wrapper-mask {
              overflow:hidden;
              width:100%;
              height: 100%;
              .name-wrapper {
                position: relative;    
                display: flex;
                flex-direction: column;
                margin-top:-4.4vw;
                .outline {
                  font-family: "GTPressuraLCGVMono-Regular";
                  font-size:  4.4vw;
                  font-style: normal;
                  font-weight: 400;
                  line-height:  100%;
                  text-transform: uppercase;
                  color: transparent;
                  -webkit-text-stroke: 1.5px #7B7B7B;
                  text-stroke: 1.5px #7B7B7B;
                  
                }
                .solid {
                  font-family: "GTPressuraLCGVMono-Regular";
                  font-size:  4.4vw;
                  font-style: normal;
                  font-weight: 400;
                  line-height:  100%;
                  text-transform: uppercase;
                  color: black;
                  -webkit-text-stroke: none;
                  text-stroke:none;
                  
                }
        
              }
            }
            
          }
          &.cell-2 {
            // width:23.6vw;
            width:39.3vw;
            height: 3.2vw;
            color: #111;
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-size: 1.388vw;
            font-style: normal;
            font-weight: 400;
            line-height:  120%;
            letter-spacing: -0.01388px;
          }
          &.cell-3 {
            width:15.7vw;
            height: 3.2vw;
            color: #111;
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-size: 1.111vw;
            font-style: normal;
            font-weight: 400;
            line-height:  120%;
            letter-spacing: -0.01388px;
          }
          &.cell-4 {
            width:7vw;
            height: 3.2vw;
            
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            -webkit-text-stroke: 0 !important;
            text-stroke: 0 !important;

            .pill {
              color: #7B7B7B;
              font-family: "GTPressuraLCGVMono-Regular";
              font-size: .833vw;
              font-style: normal;
              font-weight: 400;
              line-height:100%;
              letter-spacing: -0.00833vw;
              text-transform: uppercase;
              margin:0;
              padding:.325vw;
              padding-left:.6vw;
              padding-right:.6vw;
              display:flex;
              align-items: center;
              justify-content: center;
              border-radius: 2.77vw;
              border: 1px solid  #7B7B7B;
              box-sizing: border-box;
              &.strength {
                margin-bottom:.4vw;
              }

              &.blue {
                background: #2990F1;
                color:#CFC9C9;
                border:none;
              }

              &.white {
                background:#ECECEC;
                color:#111;
                border:none;
              }

              &.purple {
                background:#A5115C;
                color:#CFC9C9;
                border:none;
              }

              &.yellow {
                background:#E8C50B;
                color:#111;
                border:none;
              }

              &.orange {
                background:#EC831A;
                color:#111;
                border:none;
              }

              &.green {
                background:#5C9E07;
                color:#CFC9C9;
                border:none;
              }

              &.pink {
                background:#DE9DB3;
                color:#111;
                border:none;
              }

              &.red {
                background:#CF1408;
                color:#CFC9C9;
                border:none;
              }

              &.beige {
                background:#CCB599;
                color:#111;
                border:none;
              }


            }

          }
        }
      }

    }

    &.grid-style {
      flex-direction: row;
      flex-wrap:wrap;
      .headings {
        display: none;
      }

      .story-block {
        margin:0;
        padding:0;
        box-sizing: border-box;
        width: 25%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        border: 1px solid #111;
        margin-bottom:-1px;
        margin-right:-1px;
        padding:1.66vw;
        height: 100%;
        position: relative;
        overflow:hidden;
        pointer-events: all;
        cursor: pointer;

        .cell-background {
          box-sizing: border-box;
          position: absolute;
          z-index:-1;
          width:100%;
          height: 100%;
          margin:-1.66vw;
          padding:0;
          mask-size: 0% 0%;
          mask-position: 50% 53%;
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          pointer-events: none;
          
        }
      

        &.grid-style-2 {
          width: 50%;
        }

        .story-cell-wrapper {
          margin:0;
          padding:0;
          display: flex;
          flex-direction: column;
          height: 5.35vw;
          margin-bottom: 1.66vw;
          pointer-events: none;
        }

        .story-cell {
          padding:0;
          margin:0;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          width:100%; 
          height: 100%;
          pointer-events: none;

          &.cell-0{
            width:100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height:100%;
            margin-bottom: 1.66vw;
            box-sizing: border-box;
            pointer-events: none;
            >img {
              height: 8.33vw;
              width: auto;
              box-sizing: border-box;
              pointer-events: none;
            }
          }

          &.cell-1 {
            margin:0;
            padding:0;
            
            font-family: "GTPressuraLCGVMono-Regular";
            font-size:  4.4vw;
            font-style: normal;
            font-weight: 400;
            line-height:  100%;
            text-transform: uppercase;
            color: transparent;
            -webkit-text-stroke: 1.5px #7B7B7B;
            text-stroke: 1.5px #7B7B7B;
          }
          &.cell-2 {
            padding:0;
            margin:0;
            color: #111;
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-size: 1.388vw;
            font-style: normal;
            font-weight: 400;
            line-height:  120%;
            letter-spacing: -0.01388px;
          }
          &.cell-3 {
            padding:0;
            margin:0;
            margin-top:-1.66vw;
            color: #111;
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-size: 1.111vw;
            font-style: normal;
            font-weight: 400;
            line-height:  120%;
            letter-spacing: -0.01388px;
          }
          &.cell-4 {
            width:7vw;
            
            color: #111;
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-size: 1.111vw;
            font-style: normal;
            font-weight: 400;
            line-height:  120%;
            letter-spacing: -0.01388px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            gap:.55vw;
            margin-top:.55vw;

            .pill {
              margin:0;
              padding:0;
              color: #7B7B7B;
              leading-trim: both;
              text-edge: cap;
              font-family: "GTPressuraLCGVMono-Regular";
              font-size: .833vw;
              font-style: normal;
              font-weight: 400;
              line-height:100%;
              letter-spacing: -0.00833vw;
              text-transform: uppercase;
              margin:0;
              padding:.325vw;
              padding-left:.6vw;
              padding-right:.6vw;
              display:flex;
              align-items: center;
              justify-content: center;
              border-radius: 2.77vw;
              border: 1px solid  #7B7B7B;
              box-sizing: border-box;
              &.strength {
                margin-bottom:.4vw;
              }

              &.blue {
                background: #2990F1;
                color:#CFC9C9;
                border:none;
              }

              &.white {
                background:#ECECEC;
                color:#111;
                border:none;
              }

              &.purple {
                background:#A5115C;
                color:#CFC9C9;
                border:none;
              }

              &.yellow {
                background:#E8C50B;
                color:#111;
                border:none;
              }

              &.orange {
                background:#EC831A;
                color:#111;
                border:none;
              }

              &.green {
                background:#5C9E07;
                color:#CFC9C9;
                border:none;
              }

              &.pink {
                background:#DE9DB3;
                color:#111;
                border:none;
              }

              &.red {
                background:#CF1408;
                color:#CFC9C9;
                border:none;
              }

              &.beige {
                background:#CCB599;
                color:#111;
                border:none;
              }


            }

          }
        }
      }
    }


  }

  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    min-height: 60vh;
    width:calc(100%);
    padding:0;
    margin:0;
    position: relative;
    color:#ECECEC;
    margin-top:3.33vw;
    box-sizing: border-box;
  
    .stories-grid-inner {
      width:calc(100% - 8vw);
      display: flex;
      box-sizing: border-box;

      &.grid-style {
        flex-direction: row;
        flex-wrap: wrap;
        .headings {
          display: none;
        }
      }
  
      &.list-style {
        
        .headings {
          width: 100%;
          font-size: 3vw;
          letter-spacing: -0.03vw;
          padding-bottom:2vw;
          box-sizing: border-box;

          .heading {
            padding-left:4vw;
            box-sizing: border-box;
            &.col-0 {
              width:35vw;
            }
            &.col-1 {
              width:auto;
            }
            &.col-2 {
              display: none;
            }
            &.col-3 {
              display: none;
            }
          }
        }
  
        .story-block {
          width: 100%;
          padding-top:4vw;
          padding-bottom:4vw;
          box-sizing: border-box;

          .story-cell {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width:100%; 
            height: 100%;
            padding-left:2vw;
            cursor: pointer;
            box-sizing: border-box;
            
            &.cell-0{
              width:39vw;
              height:100%;
              >img {
                height: 10.2vw;
                width: auto;
              }
            }
  
            &.cell-1 {
              margin:0;
              padding:0;
              width:auto;
              font-family: "GTPressuraLCGVMono-Regular";
              font-size:  10vw;
              font-style: normal;
              font-weight: 400;
              line-height:  100%;
              text-transform: uppercase;
              color: #111;
              -webkit-text-stroke: unset;
              text-stroke:unset;

              .name-wrapper-mask {
                .name-wrapper {
                  .outline {
                    font-size:  10vw;
                    color: #111;
                   -webkit-text-stroke: unset;
                    text-stroke:unset;
                  }
                }
              }
              
              
              
            }
            &.cell-2 {
              display: none;
            }
            &.cell-3 {
              display: none;
            }
            &.cell-4 {
              display: none;
              .pill {
               display: none;
              }
  
            }
          }
        }
  
      }
  
      &.grid-style {
        flex-direction: column;
        flex-wrap:nowrap;
        .headings {
          display: none;
        }

        
  
        .story-block {
          margin:0;
          padding:0;
          box-sizing: border-box;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          border: 1px solid #111;
          margin-bottom:-1px;
          margin-right:-1px;
          padding:6.1vw;
          height: 100%;
  
          &.grid-style-2 {
            width: 100%;
          }
  
          .story-cell-wrapper {
            margin:0;
            padding:0;
            display: flex;
            flex-direction: column;
            height: auto;
            margin-bottom: 6.1vw;
          }
  
          .story-cell {
            padding:0;
            margin:0;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            width:100%; 
            height: 100%;
            
  
            &.cell-0{
              width:100%;
              display: flex;
              align-items: center;
              justify-content: center;
              height:100%;
              margin-bottom: 6.1vw;
              >img {
                height: 30vw;
                width: auto;
              }
            }
  
            &.cell-1 {
              margin:0;
              padding:0;
              
              font-family: "GTPressuraLCGVMono-Regular";
              font-size:  16.28vw;
              font-style: normal;
              font-weight: 400;
              line-height:  100%;
              text-transform: uppercase;
              color: transparent;
              -webkit-text-stroke: 1px #7B7B7B;
              text-stroke: 1px #7B7B7B;
              leading-trim: both;
              text-edge: cap;
            }
            &.cell-2 {
              padding:0;
              margin:0;
              color: #111;
              leading-trim: both;
              text-edge: cap;
              font-family: "HTQ-Waldenburg-Halbschmal";
              font-size: 6.1vw;
              font-style: normal;
              font-weight: 400;
              line-height:  100%;
              letter-spacing: -0.061vw;
            }
            &.cell-3 {
              padding:0;
              margin:0;
              margin-top:-1.66vw;
              color: #111;
              leading-trim: both;
              text-edge: cap;
              font-family: "HTQ-Waldenburg-Halbschmal";
              font-size: 1.111vw;
              font-style: normal;
              font-weight: 400;
              line-height:  120%;
              letter-spacing: -0.01388px;
            }
            &.cell-4 {
              width:7vw;
              
              color: #111;
              leading-trim: both;
              text-edge: cap;
              font-family: "HTQ-Waldenburg-Halbschmal";
              font-size: 1.111vw;
              font-style: normal;
              font-weight: 400;
              line-height:  120%;
              letter-spacing: -0.01388px;
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: flex-start;
              gap:3vw;
              margin-top:3vw;
  
              .pill {
                margin:0;
                padding:0;
                color: #7B7B7B;
                leading-trim: both;
                text-edge: cap;
                font-family: "GTPressuraLCGVMono-Regular";
                font-size: 3vw;
                font-style: normal;
                font-weight: 400;
                line-height:100%;
                letter-spacing: unset;
                text-transform: uppercase;
                margin:0;
                padding:1.2vw;
                padding-left:2.4vw;
                padding-right:2.4vw;
                display:flex;
                align-items: center;
                justify-content: center;
                border-radius: 10vw;
                border: 1px solid  #7B7B7B;
                box-sizing: border-box;
                &.strength {
                  margin-bottom:.4vw;
                }
  
                &.blue {
                  background: #2990F1;
                  color:#CFC9C9;
                  border:none;
                }
  
                &.white {
                  background:#ECECEC;
                  color:#111;
                  border:none;
                }
  
                &.purple {
                  background:#A5115C;
                  color:#CFC9C9;
                  border:none;
                }
  
                &.yellow {
                  background:#E8C50B;
                  color:#111;
                  border:none;
                }
  
                &.orange {
                  background:#EC831A;
                  color:#111;
                  border:none;
                }
  
                &.green {
                  background:#5C9E07;
                  color:#CFC9C9;
                  border:none;
                }
  
                &.pink {
                  background:#DE9DB3;
                  color:#111;
                  border:none;
                }
  
                &.red {
                  background:#CF1408;
                  color:#CFC9C9;
                  border:none;
                }
  
                &.beige {
                  background:#CCB599;
                  color:#111;
                  border:none;
                }
  
  
              }
  
            }
          }
        }
      }
  
  
    }
  }


 
}


