.landing {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
  min-height: 100%;
  width:calc(100%);
  padding:0;
  margin:0;
  position: relative;
  top:0;
  left:0;
  background-color:#DBDCD5; 
  background-image:url("../../assets/images/noise.png"); 
  background-repeat: repeat; 
  background-size: auto;
  flex-grow:1;

  .landing-main {
    display: flex;
    width:100%;
    height: 100vh;
    position: relative;
    top:0;
    left:0;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    flex-shrink: 0;

    .mobile-title{
      display: none;
    }

    .title {
      display: flex;
      font-family: "HTQ-Waldenburg-FettSchmal";
      font-feature-settings: 'case' on;
      font-size: 9.166vw;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -.09166vw;
      text-transform: uppercase;
      opacity: 0;
      align-self: flex-start;
      margin:0;
      padding:0;
      margin-left:2.77vw;
      line-height: 100%;
      user-select: none;
    }
  
  
    .sub-title {
      color: #111;
      font-family: "HTQ-Waldenburg-Halbschmal";
      font-size: 1.3888vw;
      letter-spacing: -.013888vw;
      font-style: normal;
      font-weight: 400;
      line-height:  125%;
      opacity: 0;
      width: 60vw;
      align-self: flex-start;
      margin:0;
      padding:0;
      margin-left:2.77vw;
      margin-top:2vw;
      user-select: none;
    }
  
    .up-arrow {
      margin:0;
      padding:0;
      width:2.77vw;
      position: absolute;
      right:14.3vw;
      border:none;
      background:none;
      cursor: pointer;
      margin-bottom:22vw;
      z-index: 1;
  
      transition: .3s transform ease-out;
  
      >svg {
        pointer-events: none;
      }
      
      &:hover {
        transform: translateY(-.3vw) scale(1.1) !important;
      }

      display: none;
        
      
    }
  
    .down-arrow {
      margin:0;
      padding:0;
      width:2.77vw;
      position: absolute;
      right:14.3vw;
      border:none;
      background:none;
      cursor: pointer;
      margin-top:20vw;
      transform: rotate(180deg);
      z-index: 1;
      transition: .3s transform ease-out;
      
      >svg {
        pointer-events: none;
      }
      
      &:hover {
        transform: translateY(.3vw) scale(1.1) rotate(180deg) !important;
      }

      display: none;
  
    }
  
    .phone{
      font-size: 1.1vw;
      font-weight: 400;
      color: #7B7B7B;
      font-feature-settings: 'case' on;
      font-family: "HTQ-Waldenburg-Halbschmal";
      margin:0;
      padding:0;
      position: absolute;
      right:5.4vw;
      border:none;
      background:none;
      cursor: pointer;
      margin-bottom:-9.8vw;
      text-align: center;
      width:20vw;
      opacity: 0;
      user-select: none;
    }
  
    .pill-canvas {
      cursor: pointer;
      width:20vw;
      height: 20vw;
      position: absolute;
      right:5.3vw;
      margin-bottom:5vw;
      background:transparent;
      
      canvas { cursor: pointer;}
  
  
      .loading-wrapper {
        width:100% !important;
        height: 100% !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        position: absolute;
        top:.5vw !important;
        left:0;
        transform: unset !important;
      }
  
      .lf-player-container {
        width:100%;
        height: 100%;
        filter:grayscale(1);
      }
      
    }
  }

  .news {
    .news-title {
      margin:0;
      padding:0;
      font-family: "HTQ-Waldenburg-FettSchmal";
      font-size: 5.5vw;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -.055vw;
      text-transform: uppercase;
      margin-left:2.77vw;
      line-height: 90%;
    }

    .news-items{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left:2.77vw;
      margin-top:3.33vw;
      margin-bottom:8.33vw;
      width:calc(100% - 5.55vw);
      color:  #111;

      .news-item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom:2.22vw;

        .news-item-block {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .title{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            display: flex;
            font-family: "GTPressuraLCGVMono-Regular";
            font-size: .833vw;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: -0.0083vw;
            text-transform: uppercase;
            margin-bottom:0;
            border-bottom:1px solid #111;
            padding-bottom:.55vw;
            padding-top:.55vw;
            padding-left:.55vw;

           

          }

          &.news-item-date {
            width:17.70833vw;
          }

          &.news-item-title {
            width:17.70833vw;
          }

          &.news-item-description {
            width:calc(100% - 17.70833vw*2)
          }

          

          .text {
            font-family: "HTQ-Waldenburg-Halbschmal";
            border-left:1px solid #111;
            padding-top:.55vw;
            padding-left:.55vw;
            min-height: 2.77vw;
            color:  #111;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-size: 1.11vw;
            font-style: normal;
            font-weight: 400;
            line-height: 125%;
          }

          &.news-item-description {
            .text {
              width:calc(100% - 2.77vw);
            }
          }
        }

        &.hide-desktop{
          .title{
            text-indent: -10000px;
          }
          margin-top:-1.1vw;
        }
      }
    }
  }


 .landing-list {
    display: flex;
    width:100%;
    height: auto;
    position: relative;
    top:0;
    left:0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom:7vw;

    .list-titles{
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      justify-content: flex-start;

      .list-title {
        margin:0;
        padding:0;
        font-family: "HTQ-Waldenburg-FettSchmal";
        font-size: 5.5vw;
        font-style: normal;
        font-weight: 700;
        letter-spacing: -.055vw;
        text-transform: uppercase;
        margin-left:2.77vw;
        line-height: 92%;

        &.row-1 {
          margin-left:19.6vw;
        }
      }
    }
    
 }


  

  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
    .landing-main {
      display: flex;
      width:100%;
      height: auto;
      min-height: auto;
      position: relative;
      top:0;
      left:0;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
  
      flex-shrink: 0;
      
      .title {
        display: none;
      }

      .mobile-title {
        display: flex;
        font-feature-settings: 'case' on;
        font-family: "HTQ-Waldenburg-FettSchmal";
        font-size: 24.42vw;
        text-align: center;
        font-style: normal;
        font-weight: 700;
        letter-spacing: -.2442vw;
        text-transform: uppercase;
        opacity: 0;
        align-self: center;
        margin:0;
        padding:0;
        margin-left:unset;
        line-height: 80%;
        user-select: none;
        margin-top: 46vw;
      }

      .sub-title {
        color: #111;
        font-family: "HTQ-Waldenburg-Halbschmal";
        font-size: 4vw;
        letter-spacing: -.04vw;
        font-style: normal;
        font-weight: 400;
        line-height:  125%;
        width: calc(100% - 8vw);
        align-self: flex-start;
        margin-left:4vw;
        margin-top:18vw;
        margin-bottom:18vw;
      }

      .phone{
        font-size: 4vw;
        font-feature-settings: 'case' on;
        font-weight: 400;
        color: #7B7B7B;
        font-family: "HTQ-Waldenburg-Halbschmal";
        margin:0;
        padding:0;
        position: relative;
        right:unset;
        border:none;
        background:none;
        cursor: pointer;
        margin-bottom:0;
        text-align: center;
        width:auto;
        opacity: 0;
        user-select: none;
        margin-top:8vw;
      }
    
      .pill-canvas {
        
        cursor: pointer;
        pointer-events: all;
        touch-action: none;
        width:45vw;
        height: 45vw;
        position: absolute;
        right:unset;
        margin-top: 92vw;
        background:transparent;
        
        div {
          user-select: none;
        }
        canvas { 
          // cursor: default;
          // pointer-events: none !important;
        }
    
    
        .loading-wrapper {
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          width:100% !important;
          height: 100% !important;
          position: absolute;
          // top:1.5vw !important;
          left:0;
          transform: unset !important;
         
        }
    
        .lf-player-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width:100%;
          height: 100%;
          filter:grayscale(1);
        }
        
      }
    }

    .landing-list {
      display: flex;
      width:100%;
      height: auto;
      position: relative;
      top:0;
      left:0;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-bottom:14vw;
  
      .list-titles{
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        justify-content: flex-start;
        margin-bottom:10vw;

        .list-title {
          margin:0;
          padding:0;
          font-family: "HTQ-Waldenburg-FettSchmal";
          font-size: 12.2vw;
          font-style: normal;
          font-weight: 700;
          letter-spacing: -.122vw;
          text-transform: uppercase;
          margin-left:4vw;
          line-height: 92%;
  
          &.row-1 {
            margin-left:19.6vw;
          }
        }
      }
      
   }


   .news {
    width:calc(100% - 5.55vw);
    margin-top:5.55vw;
    .news-title {
      margin:0;
      padding:0;
      font-family: "HTQ-Waldenburg-FettSchmal";
      font-size: 12.2vw;
      font-style: normal;
      font-weight: 700;
      letter-spacing: -.122vw;
      text-transform: uppercase;
      margin-left:4vw;
      line-height: 92%;
    }


    .news-items{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left:4vw;
      margin-top:3.33vw;
      margin-bottom:24vw;
      width:calc(100% - 4vw);
      color:  #111;

      .news-item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom:2.22vw;
        width:calc(100%);
        margin-top:8.4vw;
        flex-wrap: wrap;

        .news-item-block {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .title{
            
            display: flex;
            font-family: "GTPressuraLCGVMono-Regular";
            font-size: 3vw;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: -0.03vw;
            text-transform: uppercase;
            margin-bottom:0;
            border-bottom:1px solid #111;
            padding-bottom:2vw;
            flex-grow:1;
            flex-shrink:0;
            box-sizing: border-box;
            padding-left:2vw;
          }

          &.news-item-date {
            width:calc(50% - 2vw);
            padding-right: 2vw;
          }

          &.news-item-title {
            width:calc(50% - 2vw);
            padding-left: 2vw;
          }

          &.news-item-description {
            // display:none;
            width: calc(100%);
            margin-top:2.77vw;
            
          }

          .text {
            width:100%;
            color:  #111;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-size: 4vw;
            font-style: normal;
            font-weight: 400;
            line-height: 125%;
            padding-left:2vw;
            padding-top:2vw;
          }

          &.news-item-description {
            .text {
        
              width:calc(100% - 2.77vw);
            }
          }
        }

        &.hide-desktop{
          .title{
            text-indent: unset;
          }
          margin-top:8.4vw;
        }
      }
    }

   }
  
  
  }


 
}

