.stories {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
  min-height: 100%;
  width:calc(100%);
  padding:0;
  margin:0;
  position: relative;
  top:0;
  left:0;
  background-color:#CFC9C9; 
  background-image:url("../../assets/images/noise.png"); 
  background-repeat: repeat; 
  background-size: auto;


  .stories-main {
    display: flex;
    width:100%;
    height: 100vh;
    position: relative;
    top:0;
    left:0;
    align-items:flex-start;
    justify-content: center;
    flex-direction: column;

    flex-shrink: 0;
    margin-bottom:-5vh;

    .title {
      color: #111;
      leading-trim: both;
      text-edge: cap;
      font-family: "HTQ-Waldenburg-FettSchmal";
      font-size:19.167vw;
      font-style: normal;
      font-weight: 700;
      line-height:78.261%;
      letter-spacing: -0.575vw;
      text-transform: uppercase;
      margin-left: 2.77vw;
  
      .title-icon {
        width:13.68vw;
      }
    }

    
  


  }

  .stories-list {
    display: flex;
    width:100%;
    height: auto;
    position: relative;
    top:0;
    left:0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom:7vw;

    .stories-list-mobile {
      display: none;
    }

    .stories-headings {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width:calc(100% - 5.55vw);
      display: flex;
      font-family: "GTPressuraLCGVMono-Regular";
      font-size: .833vw;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.0083vw;
      text-transform: uppercase;
      margin-bottom:0;
      border-bottom:1px solid #111;
      padding-bottom:.55vw;
      
      .heading {
        font-family: "GTPressuraLCGVMono-Regular";
        padding-left:.55vw;
        &.col-0 {
          width:7.22vw;
        }
        &.col-1 {
          width:62.466vw;
        }
        &.col-2 {
          width:15.25vw;
        }
        &.col-3 {
          
        }
      }
    }

    .stories-headings-content {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width:calc(100% - 5.55vw);
      display: flex;
      // font-family: "GTPressuraLCGVMono-Regular";
      // font-size: .833vw;
      // font-style: normal;
      // font-weight: 400;
      // line-height: 100%;
      // letter-spacing: -0.0083vw;
      // text-transform: uppercase;
      margin-bottom:2.77vw;
      color:  #111;
      leading-trim: both;
      text-edge: cap;
      /* Halbschmal/16pt */
      font-family: "HTQ-Waldenburg-Halbschmal";
      font-size: 1.11vw;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      
      
      .heading-content {
        font-family: "HTQ-Waldenburg-Halbschmal";
        border-left:1px solid #111;
        padding-top:.55vw;
        padding-left:.55vw;
        min-height: 2.77vw;
        &.col-0 {
          width:7.22vw;
          color:  #111;
          leading-trim: both;
          text-edge: cap;
          /* Halbschmal/16pt */
          font-family: "HTQ-Waldenburg-Halbschmal";
          font-size: 1.11vw;
          font-style: normal;
          font-weight: 400;
          line-height: 125%;
        }
        &.col-1 {
          width:52.366vw;
          padding-right:10vw;
          color:  #111;
          leading-trim: both;
          text-edge: cap;
          /* Halbschmal/16pt */
          font-family: "HTQ-Waldenburg-Halbschmal";
          font-size: 1.11vw;
          font-style: normal;
          font-weight: 400;
          line-height: 125%;
        }
        &.col-2 {
          width:15.15vw;
          .option{
            font-size:1.11vw;
            text-transform: none;
            color: #111;
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-style: normal;
            font-weight: 400;
            line-height: 125%;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }

            &.active {
              text-decoration: underline;
            }
          }
          
        }
        &.col-3 {
          font-size:1.111vw;
          .option{
            font-size:1.111vw;
            text-transform: none;
            color: #111;
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-style: normal;
            font-weight: 400;
            line-height: 1.388vw;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }

            &.active {
              text-decoration: underline;
            }
          }
          
        }
      }
    }
    
 }




  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
    
    .stories-main {
      width:100%;
      height: auto;
      flex-shrink: unset;

      .title {
        margin-top:55vw;
        color: #111;
        leading-trim: both;
        text-edge: cap;
        font-family: "HTQ-Waldenburg-FettSchmal";
        font-size:30.53vw;
        font-style: normal;
        font-weight: 700;
        line-height:78.261%;
        letter-spacing: -0.6vw;
        text-transform: uppercase;
        margin-left:4vw;
    
        .title-icon {
          width:21.88vw;
        }
      }
    }


    .stories-list {
      
      margin-bottom:12vw;

      .stories-headings {
        display: none;
      }

      .stories-headings-content {
        display: none;
      }
      
      .stories-list-mobile {
        display: flex;
        width:100%;
        flex-direction: column;
        align-items: center;

        .stories-row {
          width: 100%;
          align-items: center;
          display: flex;
          flex-direction: column;
          margin-bottom:2vw;
        }
        .stories-headings {
          width:calc(100% - 8vw);
          display: flex;
          font-family: "GTPressuraLCGVMono-Regular";
          font-size: 3vw;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          letter-spacing: -0.03vw;
          text-transform: uppercase;
          margin-bottom:0;
          border-bottom:1px solid #111;
          padding-bottom:2vw;
          flex-grow:1;
          flex-shrink:0;
          box-sizing: border-box;
          
          .heading {
            font-family: "GTPressuraLCGVMono-Regular";
            padding-left:2vw;
            &.col-0 {
              width:100%;
            }
            &.col-1 {
              width:100%;
            }
            &.col-2 {
              width:50%;
            }
            &.col-3 {
              width:50%;
            }
          }
        }
    
        .stories-headings-content {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          width:calc(100% - 8vw);
          display: flex;
          // font-family: "GTPressuraLCGVMono-Regular";
          // font-size: .833vw;
          // font-style: normal;
          // font-weight: 400;
          // line-height: 100%;
          // letter-spacing: -0.0083vw;
          // text-transform: uppercase;
          margin-bottom:4vw;
          color:  #111;
          leading-trim: both;
          text-edge: cap;
          /* Halbschmal/16pt */
          font-family: "HTQ-Waldenburg-Halbschmal";
          font-size: 4vw;
          font-style: normal;
          font-weight: 400;
          line-height: 125%;
          
          
          .heading-content {
            font-family: "HTQ-Waldenburg-Halbschmal";
            border-left:1px solid #111;
            padding-top:2vw;
            padding-left:2vw;
            min-height: 2.77vw;
            &.col-0 {
              width:100%;
              color:  #111;
              leading-trim: both;
              text-edge: cap;
              /* Halbschmal/16pt */
              font-family: "HTQ-Waldenburg-Halbschmal";
              font-size: 4vw;
              font-style: normal;
              font-weight: 400;
              line-height: 125%;
            }
            &.col-1 {
              width:100%;
              padding-right:10vw;
              color:  #111;
              leading-trim: both;
              text-edge: cap;
              /* Halbschmal/16pt */
              font-family: "HTQ-Waldenburg-Halbschmal";
              font-size: 4vw;
              font-style: normal;
              font-weight: 400;
              line-height: 125%;
            }
            &.col-2 {
              width:50%;
              .option{
                font-size:4vw;
                text-transform: none;
                color: #111;
                leading-trim: both;
                text-edge: cap;
                font-family: "HTQ-Waldenburg-Halbschmal";
                font-style: normal;
                font-weight: 400;
                line-height: 145%;
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
    
                &.active {
                  text-decoration: underline;
                }
              }
              
            }
            &.col-3 {
              font-size:4vw;
              width:50%;
              .option{
                font-size:4vw;
                text-transform: none;
                color: #111;
                leading-trim: both;
                text-edge: cap;
                font-family: "HTQ-Waldenburg-Halbschmal";
                font-style: normal;
                font-weight: 400;
                line-height: 145%;
                cursor: pointer;
                &:hover {
                  text-decoration: underline;
                }
    
                &.active {
                  text-decoration: underline;
                }
              }
              
            }
          }
        }
      }
   }
  }


 
}


