.background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: auto;
  min-height: 100%;
  width:calc(100%);
  padding:0;
  margin:0;
  position: relative;
  background-color:#E4E2CD; 
  background-image:url("../../assets/images/noise.png"); 
  background-repeat: repeat; 
  background-size: auto;



  .title {
    color: #111;
    leading-trim: both;
    text-edge: cap;
    font-family: "HTQ-Waldenburg-FettSchmal";
    font-size:19.167vw;
    font-style: normal;
    font-weight: 700;
    line-height:78.261%;
    letter-spacing: -0.575vw;
    text-transform: uppercase;
    margin-left: 2.77vw;

    .title-icon {
      width:24.67vw;
    }
  }

  .block {
    margin:0;
    padding:0;
    color:  #111;
    leading-trim: both;
    text-edge: cap;
    font-family: "HTQ-Waldenburg-Halbschmal";
    font-size: 2.22vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.022vw;
    width: calc(100% - 5.55vw);
    margin-left: 2.77vw;
    box-sizing: border-box;
    position: relative;

    &.bottom-1 {
      margin-bottom:8.3vw;
    }

    &.top-1 {
      margin:8.3vw !important;
    }

    .label {
      position: absolute;
      top:.833vw; 
      left:-7.8vw;
      color: var(--Primary-Black, #111);
      font-family: "GTPressuraLCGVMono-Regular";
      font-size: .833vw;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -.0833vw;
      text-transform: uppercase;
      opacity:.5;
     
    }

    &.indent-block {
     
      padding-left:7.9vw;
      padding-right:7.9vw;
      text-indent: 8.4vw;
      margin-bottom:5.55vw;
    }

    &.indent-block-2 {   
      padding-left:15.8vw;
      padding-right:12vw;
      text-indent: 8.4vw;
      margin-bottom:5.55vw;

      .label {
        left:7.6vw;
      }
    }

    &.indent-block-3 {  
      padding-left:8.4vw;
      padding-right:24vw;
      text-indent: 8.4vw;
      margin-bottom:5.55vw;
      .label {
        left:0vw;
      }
    }

    &.indent-block-4 {  
      padding-left:0vw;
      padding-right:8.4vw;
      text-indent: 8.4vw;
      margin-bottom:5.55vw;
    }

    &.indent-block-5 {  
      padding-left:0vw;
      padding-right:0;
      margin-left:auto;
      margin-right:auto;
      width:calc(100% - 21.5vw);
      text-indent: 8.4vw;
      margin-bottom:5.55vw;
      margin-top:24vw;
    }

    &.inset {
      text-indent: 8.4vw;
      padding-left:32vw;
      padding-right:8vw;

      .label {
        left:23.5vw;
      }
    }

    .block-p-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-top:1.77vw;
      // margin-bottom:5.55vw;
      .block-p-button {
        
        
        &.outline {
          margin:0;
          padding:0;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0vw 1.388vw;
          height: 2.77vw;
          box-sizing: border-box;
          border-radius: 1.388vw;
          border: 1px solid  #111;
          color:#111;
          background: none;
          text-align: center;
          leading-trim: both;
          text-edge: cap;
          font-family: "HTQ-Waldenburg-FettSchmal";
          font-size: 1.11vw;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
          cursor: pointer;
          margin-bottom:5.55vw;
          text-transform: uppercase;
        }

        &.orange {
          margin:0;
          padding:0;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0vw 1.388vw;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 2.77vw;
          box-sizing: border-box;
          border-radius: 1.388vw;
          background:#F8401C;
          border:none;
          color:  #111;
          text-align: center;
          font-family: "HTQ-Waldenburg-FettSchmal";
          font-size: 1.11vw;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
          cursor: pointer;
          text-transform: uppercase;
        }

        transition: all .3s ease-out;

        &:hover {
          color:#D0E4CD;
          background:#111;
        }
      }
    }

    .block-icon {
      height:1.6vw;
      width: auto;
      margin-left:.55vw;
      margin-right:.22vw;

      &.union {
        height:2vw;
        margin-bottom:-.4vw;
      }
    }

    &.titles{
      margin-bottom:7.22vw;
      margin-top:0;
      box-sizing: border-box;
      overflow:hidden;

      .block-title {
        color: #111;
        leading-trim: both;
        text-edge: cap;
        font-family: "HTQ-Waldenburg-FettSchmal";
        font-size: 13.88vw;
        font-style: normal;
        font-weight: 700;
        line-height:76%;
        letter-spacing: -.277vw;
        text-transform: uppercase;
        margin-bottom:1.11vw;
        width:calc(100%);
        box-sizing: border-box;

        &.medium-size {
          font-size: 8.33vw;
        }
        
        &.left {
          text-align: left;
        }

        &.right {
          text-align: right;
        }
        
        &.indent-1 {
          margin-left:15vw;
        }

        &.indent-2 {
          margin-left:8vw;
        }

        &.indent-3 {
          margin-left:23vw;
        }

      }

    }

    &.full-image {
      width:100%; 
      margin:0; 
      padding:0;
      position: relative;
      line-height: 0;

      margin-bottom:13.8vw;

      .image {
        position: relative;
        width:100%;
        padding:0;
        margin:0;
      }

      .image-mobile {
        display: none;
      }

      .caption {
        position: absolute;
        bottom:.55vw;
        background:#111;
        color:#ECECEC;
        leading-trim: both;
        text-edge: cap;
        font-family: "GTPressuraLCGVMono-Regular";
        font-size: .833vw;
        font-style: normal;
        font-weight: 400;
        line-height:100%;
        letter-spacing:-.0083vw;
        text-transform: uppercase;
        max-width:calc(100% - 1.11vw );
        box-sizing: border-box;
        padding:.277vw;
        margin-left:.55vw;
      }
    }

    &.box-row {
      width:78.75vw;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom:2.5vw;
      margin-left:auto;
      margin-right: auto;

      .box-row-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border:1px #111 solid;
        padding:1.666vw;
        margin-bottom:.833vw;
        box-sizing: border-box;

        .box {
          display: flex;
          flex-direction: column;
          width: 50%;
          box-sizing: border-box;
          color:#111;
          align-self: stretch;
          justify-content: flex-start;
          background-color: transparent;
          padding:1.666vw;


          .title {
            margin:0;
            padding:0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: baseline;
            margin-top:1.666vw;
            gap:.55vw;
            margin-bottom: 1.666vw;

            .title-title {
              margin:0;
              padding:0;
              font-family: "HTQ-Waldenburg-FettSchmal";
              font-size: 2.77vw;
              font-style: normal;
              font-weight: 700;
              line-height: 100%;
              letter-spacing: -0.0277vw;

            }

            .title-subtitle{
              margin:0;
              padding:0;
              font-family: "HTQ-Waldenburg-Halbschmal";
              font-size: 1.11vw;
              font-style: normal;
              font-weight: 700;
              line-height: 100%;
              letter-spacing: normal;
            }


          }

          .text {
            margin:0;
            padding:0;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-size: 1.666vw;
            font-style: normal;
            font-weight: 400;
            line-height:  100%;
            letter-spacing: -0.0166vw;
            margin-bottom: 2.222vw;
          }

          .labels {
            display: flex;
            flex-direction: row;
            gap:.55vw;
            margin-bottom: 1.666vw;

            .label {
              border:1px solid #111;
              border-radius: 8.33vw;
              font-family: "GTPressuraLCGVMono-Regular";
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
              letter-spacing: -0.00833vw;
              text-transform: uppercase;
              font-size:.833vw;
              padding:.555vw;
              line-height: 80%;
              position: relative;
              top:unset;
              left:unset;
              opacity:1;
            }
          }

          &.black {
            background-color: #111;
            color:#E4E2CD;
            .title-title, .title-subtitle, .text { 
              color:#E4E2CD;
            }
            .label {
              color:#E4E2CD;
              border:1px solid #E4E2CD;
            }
          }
          
        }

      }

      .caption {
       
        bottom:.55vw;
        background:#111;
        color:#ECECEC;
        leading-trim: both;
        text-edge: cap;
        font-family: "GTPressuraLCGVMono-Regular";
        font-size: .833vw;
        font-style: normal;
        font-weight: 400;
        line-height:100%;
        letter-spacing:-.0083vw;
        text-transform: uppercase;
        max-width:calc(100% - 1.11vw );
        box-sizing: border-box;
        padding:.277vw;
        
      }

    }

    &.outline-titles{
      margin-bottom:0;
      margin-top:8.33vw;
      box-sizing: border-box;
      overflow:hidden;

      .block-subtitle{
        margin:0;
        padding:0;
        margin-bottom: .55vw;
        color: #111;
        font-family: "GTPressuraLCGVMono-Regular";
        font-size:.833vw;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -.008333vw;
        text-transform: uppercase;
      }

      .block-title {
        color: transparent;
        opacity: .5;
        -webkit-text-stroke: 1.5px #111;
        text-stroke: 1.5px #111;
        color: transparent;
        font-family: "HTQ-Waldenburg-FettSchmal";
        font-size: 8.33vw;
        font-style: normal;
        font-weight: 700;
        line-height:76%;
        letter-spacing: -.1666vw;
        text-transform: uppercase;
        margin-bottom:.55vw;
        width:calc(100% - 7.9vw);
        box-sizing: border-box;
        
        &.left {
          text-align: left;
        }

        &.right {
          text-align: right;
        }
        
        &.indent-1 {
          margin-left:15vw;
        }

        &.indent-2 {
          margin-left:8vw;
        }

        &.indent-3 {
          margin-left:23vw;
        }

      }

    }

    &.block-table {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap:1.66vw;
      width:78.75vw;
      margin-left:auto;
      margin-right: auto;
      margin-top:2.22vw;

      margin-bottom: 8.33vw;

      .block-table-col {
        display: flex;
        width:50%;
        position: relative;
        align-items: center;
        justify-content: flex-start;
        border:1px solid #111;
        position: relative;

        .block-table-caption {
          background:#111;
          color: #CDD8E4;
          leading-trim: both;
          text-edge: cap;
          font-family: "GTPressuraLCGVMono-Regular";
          font-size: .833vw;
          font-style: normal;
          font-weight: 400;
          line-height:100%;
          letter-spacing:-.0083vw;
          text-transform: uppercase;
          box-sizing: border-box;
          padding-left:1.66vw;
          padding-top:.55vw;
          padding-bottom:.55vw;
        }
       

        .block-table-title {
          margin:0;
          padding:0;
          margin-top:1.11vw;
          margin-bottom:2.77vw;
          leading-trim: both;
          text-edge: cap;
          font-family: "HTQ-Waldenburg-FettSchmal";
          font-size: 2.77vw;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
          letter-spacing: -0.0277vw;
          padding-left:1.66vw;
        }

        .block-table-text {
         
          margin-bottom:2.77vw;
          color: var(--Primary-Black, #111);
          leading-trim: both;
          text-edge: cap;
          font-family: "HTQ-Waldenburg-Halbschmal";
          font-size:1.666vw;
          font-style: normal;
          font-weight: 400;
          line-height:  133.333%;
          letter-spacing: -.0166vw;
          padding-left:1.66vw;
          padding-right:2.77vw;
        }

        .buttons {
          display: flex;
          flex-direction: row;
          gap:1.2vw;
          margin-bottom: 1.11vw;
          .block-table-button {
            border:1px solid #111;
            border-radius: 13.88vw;
            background: none;
            box-sizing: border-box;
            padding:.98vw;
            padding-left:1.4vw;
            padding-right:1.4vw;
            pointer-events: all;
            cursor: pointer;
            transition: .3s all ease-out;
            &:hover {
              color:#D0E4CD;
              background:#111;
            }

            color:  #111;
            text-align: center;
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-FettSchmal";
            font-size: 1.11vw;
            font-style: normal;
            font-weight: 700;
            line-height:  125%;
          }
        }
        
      }
      &.two-col {
        
        .block-table-col {
          width: calc(50%);
         
        }
      }
    }

    &.image-row {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      gap:1.66vw;
      width:78.75vw;
      margin-left:auto;
      margin-right: auto;
      margin-top:5.55vw;
      margin-bottom:11.1vw;

      .block-image-row-col {
        display: flex;
        width:50%;
        position: relative;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;

        .image-wrapper {
          display: flex;
          position: relative;
          align-items: center;
          justify-content: flex-start;
          
          .image {
            width: 100%;
          }
  
          .caption {
            position: absolute;
            bottom:.55vw;
            background:#111;
            color:#ECECEC;
            leading-trim: both;
            text-edge: cap;
            font-family: "GTPressuraLCGVMono-Regular";
            font-size: .833vw;
            font-style: normal;
            font-weight: 400;
            line-height:100%;
            letter-spacing:-.0083vw;
            text-transform: uppercase;
            max-width:calc(100% - 1.11vw );
            box-sizing: border-box;
            padding:.277vw;
            margin-left:.55vw;
          }
        }

        .block_image_row_col_table_block {
          margin-top:2.22vw;
          display: flex;
          width:50%;
          position: relative;
          display: flex;
          width:100%;
          position: relative;
          align-items: center;
          justify-content: flex-start;
          border:1px solid #111;
          position: relative;
  
          .block-table-caption {
            background:#111;
            color: #CDD8E4;
            leading-trim: both;
            text-edge: cap;
            font-family: "GTPressuraLCGVMono-Regular";
            font-size: .833vw;
            font-style: normal;
            font-weight: 400;
            line-height:100%;
            letter-spacing:-.0083vw;
            text-transform: uppercase;
            box-sizing: border-box;
            padding-left:1.66vw;
            padding-top:.55vw;
            padding-bottom:.55vw;
          }
         
  
          .block-table-title {
            margin:0;
            padding:0;
            margin-top:1.11vw;
            margin-bottom:2.77vw;
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-FettSchmal";
            font-size: 2.77vw;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -0.0277vw;
            padding-left:1.66vw;
          }
  
          .block-table-text {
           
            margin-bottom:2.77vw;
            color: var(--Primary-Black, #111);
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-size:1.666vw;
            font-style: normal;
            font-weight: 400;
            line-height:  133.333%;
            letter-spacing: -.0166vw;
            padding-left:1.66vw;
            padding-right:2.77vw;
          }
  
          .buttons {
            display: flex;
            flex-direction: row;
            gap:1.2vw;
            margin-bottom: 1.11vw;
            .block-table-button {
              border:1px solid #111;
              border-radius: 13.88vw;
              background: none;
              box-sizing: border-box;
              padding:.98vw;
              padding-left:1.4vw;
              padding-right:1.4vw;
              pointer-events: all;
              cursor: pointer;
              transition: .3s all ease-out;
              &:hover {
                color:#D0E4CD;
                background:#111;
              }
  
              color:  #111;
              text-align: center;
              leading-trim: both;
              text-edge: cap;
              font-family: "HTQ-Waldenburg-FettSchmal";
              font-size: 1.11vw;
              font-style: normal;
              font-weight: 700;
              line-height:  125%;
            }
          }
        }
       
      }

     
    }

    &.block-scroll-table {
      position: relative;
      margin:0;
      padding:0;
      display: flex;
      width:100%;

      .scroll-arrows {
        display: flex;
        flex-direction: row;
        gap:.55vw;
        position: absolute;
        top:-11.0vw;
        right:2.77vw;
        
        .arrow {
          margin:0;
          padding:0;
          color:#111;
          border-radius:8.33vw;
          border: 1px solid #111;
          background:transparent;
          padding:.833vw;
          padding-left:1.4vw;
          padding-right:1.4vw;
          box-sizing: border-box;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            width:1.4vw;
            padding:0;
            margin:0;
          }

          &.scroll-back  {
            rotate:(-180deg);
          }

          &.disable {
            opacity:.3;
          }

          transition: all .3s ease-out;

        &:hover {
          color:#D0E4CD;
          background:#111;

          svg {
            * {
              stroke:#D0E4CD !important;
            }
          }
        }
  
        }
      }

      .scrollable-area {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap:1.66vw;
        width:100%;
        padding-left:10.625vw;
        padding-right:.55vw;
        position: relative;
        overflow-x:scroll;
        overflow-y: hidden;
        margin-left:auto;
        margin-right: auto;
        margin-top:2.22vw;
        margin-bottom: 8.33vw;

        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        overflow-y: scroll;
        
        &::-webkit-scrollbar {
          display: none; /* for Chrome, Safari, and Opera */
        }

        .block-scroll-table-col {
          display: flex;
          width:32vw;
          height: 21vw;
          box-sizing: border-box;
          flex-shrink: 0;
          position: relative;
          align-items: flex-start;
          justify-content: flex-start;
          border:1px solid #111;
          border-top:2vw solid #111;
        

          .block-scroll-table-title {
            margin:0;
            padding:0;
            margin-top:1.11vw;
            margin-bottom:2.22vw;
            width:60%;
            color:  #111;
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-FettSchmal";
            font-size:2.77vw;
            font-style: normal;
            font-weight: 700;
            line-height:  100%;
            letter-spacing: -.0277vw;
            padding-left:1.11vw;
            padding-right:1.11vw;
          }

          .block-scroll-table-text {
            margin:0;
            padding:0;
            margin-bottom:2.77vw;
            color: var(--Primary-Black, #111);
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-size:1.666vw;
            font-style: normal;
            font-weight: 400;
            line-height:  133.333%;
            letter-spacing: -.0166vw;
            padding-left:1.11vw;
            padding-right:1.11vw;
          }

          .tags {
            display: flex;
            flex-direction: column;
            gap:1.2vw;
            position: absolute;
            right:2.77vw;
            top:1.11vw;
            .block-scroll-table-button {
              border:none;
              border-radius: 2.77vw;
              background: none;
              box-sizing: border-box;
              padding:.41vw;
              pointer-events:none;
              
              display: flex;
              align-items: center;
              justify-content: center;
              color: #111;
              leading-trim: both;
              text-edge: cap;
              font-family: "GTPressuraLCGVMono-Regular";
              font-size: .833vw;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
              letter-spacing:-.00833vw;
              text-transform: uppercase;
            }
          }
          
        }
      }
      
    }

    &.stepped-block{
      position: relative;
      margin:0;
      padding:0;
      display: flex;
      width:100%;
      margin-bottom:-8.4vw;

      .scrollable-area {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
        
        width:100%;
        padding-left:10.625vw;
        padding-right:.55vw;
        position: relative;
        overflow-x:scroll;
        overflow-y: hidden;
        margin-left:auto;
        margin-right: auto;
        margin-top:2.22vw;
        margin-bottom: 8.33vw;

        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        overflow-y: scroll;
        
        &::-webkit-scrollbar {
          display: none; /* for Chrome, Safari, and Opera */
        }

        .stepped-block-col {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width:32vw;
          height: 30vw;
          box-sizing: border-box;
          flex-shrink: 0;
          position: relative;
          align-items: flex-start;
          border:1px solid #111;
          margin-right:-1px;

          .stepped-block-number {
            margin:0;
            padding:0;
            margin-top:1.11vw;
            width:60%;
            leading-trim: both;
            text-edge: cap;
            font-family: "GTPressuraLCGVMono-Regular";
            -webkit-text-stroke: 1.5px #111;
            text-stroke: 1.5px #111;
            color: transparent;
            font-size: 2.77vw;
            font-style: normal;
            font-weight: 400;
            line-height:  100%;
            padding-left:1.11vw;
            padding-right:1.11vw;
          }

          .stepped-block-text {
            margin:0;
            padding:0;
            color: #111;
            leading-trim: both;
            text-edge: cap;
            font-size: 2.77vw;
            font-style: normal;
            font-weight: 700;
            line-height:100%;
            letter-spacing: -.0277vw;
            font-family: "HTQ-Waldenburg-FettSchmal";
            padding-left:1.11vw;
            padding-right:1.11vw;
            margin-bottom:1.11vw;
          }

         
        }
      }
      
    }

    .info-table-mobile {
      display: none;
    }

    &.info-table {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top:1.111vw;
      margin-bottom:7.777vw;

      .info-table-title {
        color:  #111;
        leading-trim: both;
        text-edge: cap;
        font-family: "HTQ-Waldenburg-FettSchmal";
        font-size: 2.77vw;
        font-style: normal;
        font-weight: 700;
        line-height:  100%;
        letter-spacing: -.0277vw;
        margin-bottom: 2.77vw;
      }

      .info-table-headings {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        box-sizing: border-box;
        

        .heading {
          color:  #111;
          leading-trim: both;
          text-edge: cap;
          font-family: "GTPressuraLCGVMono-Regular";
          font-size:.833vw;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          letter-spacing: -0.00833vw;
          text-transform: uppercase;
          padding-bottom:.55vw;
          padding-left:.833vw;
          box-sizing: border-box;
          flex-grow: 0;
          flex-shrink: 0;

          &.heading-0 {
            width:7.77vw;
          }

          &.heading-1 {
            width:15.694vw;
          }

          &.heading-2 {
            width:55vw;
          }

          &.heading-3 {
            width:15.694vw;
          }
        }
      }

      .info-table-table {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        box-sizing: border-box;

        .info-table-row {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          border-top: 1px solid #111;
          box-sizing: border-box;
          height: 100%;
          margin-bottom:2.22vw;

          .col {
            padding-bottom:.55vw;
            padding-left:.833vw;
            padding-top:.833vw;
            box-sizing: border-box;
            border-left: 1px solid #111;
            box-sizing: border-box;
            align-self: stretch;
            flex-grow: 0;
            flex-shrink: 0;

            &.index {
              width:7.77vw;
              color:  #111;
              leading-trim: both;
              text-edge: cap;
              font-family: "HTQ-Waldenburg-Halbschmal";
              font-size: 1.388vw;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              letter-spacing: -.01388vw;
            }
  
            &.icon{
              width:15.694vw;
              box-sizing: border-box;
              height: 100%;
              img {
                max-width:12vw;
                max-height: 8vw;
                object-fit: contain;

              }
            }
  
            &.text{
              width:55vw;
              color: var(--Primary-Black, #111);
              leading-trim: both;
              text-edge: cap;
              font-family: "HTQ-Waldenburg-Halbschmal";
              font-size: 1.388vw;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              letter-spacing: -.01388vw;
            }

            &.link {
              width:15.694vw;
              color: var(--Primary-Black, #111);
              leading-trim: both;
              text-edge: cap;
              font-family: "HTQ-Waldenburg-Halbschmal";
              font-size: 1.388vw;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              letter-spacing: -.01388vw;
              
            }
          }

        }

       
      }
    }

    &.pill-table {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width:calc(100% - 5.55vw);
      box-sizing: border-box;
      margin-bottom:8.3vw;

      .headings {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        width:100%;
        box-sizing: border-box;
        padding-bottom:.486vw;

         .heading {
          color: #111;
          font-family: "GTPressuraLCGVMono-Regular";
          font-size: .833vw;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          letter-spacing: -.0083vw;
          text-transform: uppercase;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          align-self: stretch;
          justify-content: flex-start;
          padding-left:.486vw;
          padding-right:.486vw;
          
          &.col-0 { width:15.7vw;}
          &.col-1 { width:15.7vw;}
          &.col-2 { width:23.54vw;}
          &.col-3 { width:23.54vw;}
          &.col-4 { width:7.85vw;}
          &.col-5 { width:7.85vw;}
        }
      }
      

      .pills{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .pill{
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          width:100%;
          margin-bottom:2.22vw;
          border-top:1px solid #111;

          .col {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            box-sizing: border-box;
            color: #111;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-size: 1.11vw;
            font-style: normal;
            font-weight: 400;
            line-height: 125%;
            padding-left:.486vw;
            padding-right:.486vw;
            padding-top: .8333vw;
            border-left:1px solid #111;
            align-self:stretch;
  
            .image {
              height:4.44vw ;
              width: auto;
              padding-left:.486vw;
            }

            &.col-0 { width:15.7vw; }
            &.col-1 { width:15.7vw;}
            &.col-2 { width:23.54vw;}
            &.col-3 { width:23.54vw;}
            &.col-4 { width:7.85vw;}
            &.col-5 { width:7.85vw;}
          }
        }
       

       
      }



    }

    &.pill-table-mobile {
      display: none;
    }
    &.pill-table-dekstop {
      display: flex;
    }

    &.brain-block {
      margin:0;
      padding:0;
      width:100%;
      background: #050306;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-self: flex-start;
      margin-bottom:13.8vw;

      .title {
        color: var(--Secondary-3601-Light, #E4E2CD);
        font-family: "HTQ-Waldenburg-FettSchmal";
        font-size: 8.33vw;
        font-style: normal;
        font-weight: 700;
        line-height: 80%;
        letter-spacing: -.166vw;
        width:33.82vw;
        margin-left:2.77vw;
        margin-top:8.33vw;
      }

      .text-block {
        position: relative;
        width:78.8vw;
        margin-top:8.33vw;
        display: flex;
        align-self: center;
        justify-self: center;
        margin-left:auto;
        margin-right:auto;

        .heading {
          position: absolute;
          left:0;
          top:0.833vw;
          color: #E4E2CD;
          font-family: "GTPressuraLCGVMono-Regular";
          font-size: .833vw;
          font-style: normal;
          font-weight: 400;
          line-height:  100%;
          letter-spacing: -0.00833vw;
          text-transform: uppercase;
          opacity:.5;
        }
        .text {
          color: #E4E2CD;
          font-family: "HTQ-Waldenburg-Halbschmal";
          font-size: 2.22vw;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.022vw;
          text-indent: 8.33vw;
        }
      }

      .image {
        display: flex;
        align-self: center;
        justify-self: center;
        margin-left:auto;
        margin-right:auto;
        width:78.8vw;
        height: auto;
        margin-top:5.55vw;
      }
      .image-mobile {
        display: none;
      }

      .subtext {
        color:  #E4E2CD;
        font-family: "HTQ-Waldenburg-Halbschmal";
        font-size: 1.66vw;
        font-style: normal;
        font-weight: 400;
        line-height: 133.333%;
        letter-spacing: -0.0166vw;
        display: flex;
        align-self: center;
        justify-self: center;
        margin-left:auto;
        margin-right:auto;
        width:78.8vw;
        height: auto;
        margin-top:2.22vw;
        margin-bottom:11.11vw;
      }
    }

    &.wave-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width:calc(100% - 5.55vw);
      box-sizing: border-box;
      background:#fff;
      border-radius: 2.77vw;

      margin-top:0vw;
      margin-bottom:11.1vw;

      .title1 {
        -webkit-text-stroke: 1.5px #111;
        text-stroke: 1.5px #111;
        color: transparent;
        font-family: "HTQ-Waldenburg-FettSchmal";
        font-size: 8.33vw;
        font-style: normal;
        font-weight: 700;
        line-height:  80%;
        letter-spacing: -.166vw;
        text-transform: uppercase;
        margin-top:8.33vw;
        margin-left:9vw;
      }

      .title2 {
        color: var(--Primary-Black, #111);
        font-family: "HTQ-Waldenburg-FettSchmal";
        font-size: 8.33vw;
        font-style: normal;
        font-weight: 700;
        line-height: 80%;
        letter-spacing: -.166vw;
        margin-left:23vw;
      }

      .wave-graph {
        width: 83.88vw;
        display: flex;
        align-self: center;
        margin-top:5.55vw;
        margin-bottom:5.55vw;
      }
      .wave-graph-mobile {
        display: none;
      }
    }

    &.scrollable-image-row {
      margin:0;
      padding:0;
      display: flex;
      width:100%;
      box-sizing: border-box;

      margin-bottom: 9.7vw;

      .scroll-container {
        overflow-x: scroll;
        width:100%;
        box-sizing: border-box;
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap:1.388vw;
        padding-left:2.77vw;
        padding-right:2.77vw;

        .image-block {
          display: flex;
          height: 50vw;
          box-sizing: border-box;
          position: relative;
          user-select: none;
          pointer-events: none;

          .caption {
            position: absolute;
            bottom:.55vw;
            background:#111;
            color:#ECECEC;
            leading-trim: both;
            text-edge: cap;
            font-family: "GTPressuraLCGVMono-Regular";
            font-size: .833vw;
            font-style: normal;
            font-weight: 400;
            line-height:100%;
            letter-spacing:-.0083vw;
            text-transform: uppercase;
            max-width:calc(100% - 1.11vw );
            box-sizing: border-box;
            padding:.277vw;
            margin-left:.55vw;
          }
        }

        .drag-icon {
          width:11.11vw;
          user-select: none;
          pointer-events: none;
        }
      }
    }

    &.image-text-block {
      display: flex;
      flex-direction: row;
      width:calc(100% - 5.55vw);
      gap:7.8vw;
      box-sizing: border-box;
      margin-bottom:10vw;

     

      .text-block {
        display: flex;
        width:50%;
        box-sizing: border-box;
        position: relative;
      
        padding-left:7.8vw;

        .text {
          text-indent: 7.8vw;
          position: relative;
          .label {
            position: absolute;
            top:.833vw; 
            left:-7.8vw;
            color: var(--Primary-Black, #111);
            font-family: "GTPressuraLCGVMono-Regular";
            font-size: .833vw;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: -.0833vw;
            text-transform: uppercase;
            opacity:.5;
           
          }
          
        }

       
      }

      .image-wrapper {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-start;
      
        width:50%;
        box-sizing: border-box;
        padding-top:7.8vw;

        .image {
          width: 100%;
        }

        .caption {
          position: absolute;
          bottom:.55vw;
          background:#111;
          color:#ECECEC;
          leading-trim: both;
          text-edge: cap;
          font-family: "GTPressuraLCGVMono-Regular";
          font-size: .833vw;
          font-style: normal;
          font-weight: 400;
          line-height:100%;
          letter-spacing:-.0083vw;
          text-transform: uppercase;
          max-width:calc(100% - 1.11vw );
          box-sizing: border-box;
          padding:.277vw;
          margin-left:.55vw;
        }
      }

      &.reverse {
        flex-direction: row-reverse;
        .text-block {
          padding-left:0vw;
          padding-right:7.8vw;
          align-self: flex-end;
          justify-self: flex-end;
        }

        .image-wrapper {
          padding-top: 0;
        }

      }
    }

    &.image-caption {

      display: flex;
      position: relative;
      align-items: center;
      justify-content: flex-start;
      width:62.9vw;
      box-sizing: border-box;

      align-self:center;
      margin-bottom:8.33vw;

      .image {
        width: 100%;
      }

      .caption {
        position: absolute;
        bottom:.55vw;
        background:#111;
        color:#ECECEC;
        leading-trim: both;
        text-edge: cap;
        font-family: "GTPressuraLCGVMono-Regular";
        font-size: .833vw;
        font-style: normal;
        font-weight: 400;
        line-height:100%;
        letter-spacing:-.0083vw;
        text-transform: uppercase;
        max-width:calc(100% - 1.11vw );
        box-sizing: border-box;
        padding:.277vw;
        margin-left:.55vw;
      }
    

    }
   
  }

  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
    
    .title {
      align-self:flex-start;
      width:calc(100% - 16vw);
      word-wrap: break-word;
      // overflow-wrap:break-word;
      // word-break: break-all;
      font-size:30.5vw;
      line-height:78.261%;
      letter-spacing: -0.575vw;
      margin-left: 4vw;
      margin-top:55vw;
      .title-icon {
        width:39.44vw;
      }
    }

    .block {
      margin:0;
      padding:0;
      color:  #111;
      font-family: "HTQ-Waldenburg-Halbschmal";
      font-size: 6.1vw;
      font-style: normal;
      font-weight: 400;
      line-height: 133.33%;
      letter-spacing: -0.061vw;
      width: calc(100% - 8vw);
      margin-left: 4vw;
      box-sizing: border-box;
  
      &.indent-block {
        padding-left:11.2vw;
        padding-right:4vw;
        text-indent: 8.4vw;
        margin-bottom:6.1vw;
      }

      &.indent-block-3 {
        .label {
          left:-12vw;
        }
      }
  
      &.inset {
        padding-left:11.2vw;
        padding-right:4vw;
        text-indent: 12vw;
        margin-bottom:6.1vw;
        .label { 
          left:0vw;
        }
      }

      &.indent-block-3 {  
        padding-left:0vw;
        padding-right:12vw;
        text-indent: 12vw;
        margin-bottom:5.55vw;
      }

      &.indent-block-2 {  
        padding-left:13vw;
        padding-right:8vw;
        text-indent: 12vw;
        margin-bottom:5.55vw;
        .label { 
          left:2vw;
        }

      }

      &.indent-block-4 {  
        padding-left:0vw;
        padding-right:12vw;
        text-indent: 12vw;
        margin-bottom:5.55vw;
        .label { 
          left:-12vw;
        }

      }

      &.indent-block-5  {
        margin-top:0vw;
        margin-left:4vw;
        text-indent: 12vw;
        margin-bottom:5.55vw;
        width:calc(100% - 14vw);
        .label { 
          left:-12vw;
        }

      
      }

      

      .label {
        position: absolute;
        top:1.8vw; 
        left:-12vw;
        color: var(--Primary-Black, #111);
        font-family: "GTPressuraLCGVMono-Regular";
        font-size: 2.5vw;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -.25vw;
        text-transform: uppercase;
        opacity:.5;
       
      }
  
      .block-p-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-top:6.1vw;
        margin-bottom:5.55vw;
        margin-right:-12vw;
        
        .block-p-button {
          &.outline {
            margin:0;
            padding:0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0vw 6.1vw;
            height: 11.7vw;
            box-sizing: border-box;
            border-radius: 20.35vw;
            border: 1px solid  #111;
            color:#111;
            background: none;
            text-align: center;
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-FettSchmal";
            font-size: 5vw;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            cursor: pointer;
            text-transform: uppercase;
          }
  
          &.orange {
            margin:0;
            padding:0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0vw 6.1vw;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 11.7vw;
            box-sizing: border-box;
            width: 100%;
            border-radius: 20.35vw;
            background:#F8401C;
            border:none;
            color:  #111;
            text-align: center;
            font-family: "HTQ-Waldenburg-FettSchmal";
            font-size: 6.1vw;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            cursor: pointer;
            text-transform: uppercase;
            
           
          }
        }
      }
  
      &.titles{
        margin-bottom:10.2vw;
        // margin-top:12.2vw;
        margin-top:8vw;
        box-sizing: border-box;
        overflow:hidden;
        .block-title {
          color: #111;
          font-family: "HTQ-Waldenburg-FettSchmal";
          font-size: 14.2vw;
          font-style: normal;
          font-weight: 700;
          line-height:76%;
          letter-spacing: -.142vw;
          text-transform: uppercase;
          margin-bottom:2vw;
          width:calc(100% - 8vw);
          box-sizing: border-box;
          
          &.left {
            text-align: left;
          }
  
          &.right {
            text-align: right;
          }
  
          &.indent-1 {
            margin-left:15vw;
          }
  
          &.indent-2 {
            margin-left:4vw;
          }

          &.medium-size {
            font-size: 9.8vw;
          }
  
        }
  
      }

      &.full-image {
        width:100vw;
        height: 162vw;
        margin-bottom:13.8vw;
        overflow:hidden;
        position: relative;
        
        .image {
          display: none;
        }

        
        .image-mobile {
          padding:0;
          margin:0;
          position: relative;
          display: flex;
          width:100vw;
          height: 162vw;
          object-fit: cover;
          object-position: center;
          align-self:center;
        }
  
        .caption {
          position: absolute;
          bottom:3vw;
          background:#111;
          color:#ECECEC;
          font-family: "GTPressuraLCGVMono-Regular";
          font-size: 2.54vw;
          font-style: normal;
          font-weight: 400;
          line-height:100%;
          letter-spacing:-.0254vw;
          text-transform: uppercase;
          max-width:calc(100% - 6vw );
          box-sizing: border-box;
          padding:1vw;
          margin-left:3vw;
        }
      }
  
      .block-icon {
        height:6vw;
        width: auto;
        margin-left:.2vw;
        margin-right:.2vw;
        box-sizing: border-box;
        margin-bottom:-.4vw;

        &.fda {
          height:4vw;
        }

        &.union {
          height:6vw;
          margin-left:1vw;
          margin-bottom:-1.6vw;
        }
      }
  
      &.outline-titles{
        margin-bottom:0;
        margin-top:8.33vw;
        box-sizing: border-box;
        overflow:hidden;
  
        .block-subtitle{
          margin:0;
          padding:0;
          margin-bottom:2vw;
          color: #111;
          font-family: "GTPressuraLCGVMono-Regular";
          font-size:3vw;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          letter-spacing: -.03vw;
          text-transform: uppercase;
        }
  
        .block-title {
          color: transparent;
          opacity:.5;
          -webkit-text-stroke: 1px #111;
          text-stroke: 1px #111;
          color: transparent;
          font-family: "HTQ-Waldenburg-FettSchmal";
          font-size: 16.28vw;
          font-style: normal;
          font-weight: 700;
          line-height:86%;
          letter-spacing: -.16vw;
          text-transform: uppercase;
          margin-bottom:0vw;
          width:calc(100% - 8vw);
          box-sizing: border-box;
          
          &.left {
            text-align: left;
          }
  
          &.right {
            text-align: right;
          }
          
          &.indent-1 {
            margin-left:15vw;
          }
  
          &.indent-2 {
            margin-left:8vw;
          }
  
          &.indent-3 {
            margin-left:23vw;
          }
  
        }
  
      }

      &.box-row {
        width:calc(100% - 8vw);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom:8vw;
        margin-left:auto;
        margin-right: auto;
        
  
        .box-row-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border:1px #111 solid;
          padding:4vw;
          margin-bottom:2vw;
          box-sizing: border-box;
          width: 100%;
          position: relative;
  
          .box {
            display: flex;
            flex-direction: column;
            width: 100%;
            box-sizing: border-box;
            color:#111;
            align-self: stretch;
            justify-content: flex-start;
            background-color: transparent;
            padding:4vw;
            position: relative;
  
            .title {
              margin:0;
              padding:0;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: baseline;
              margin-top:1.666vw;
              gap:.55vw;
              margin-bottom: 1.666vw;
              position: relative;
  
              .title-title {
                margin:0;
                padding:0;
                font-family: "HTQ-Waldenburg-FettSchmal";
                font-size: 8.14vw;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: -0.0277vw;
  
              }
  
              .title-subtitle{
                margin:0;
                padding:0;
                font-family: "HTQ-Waldenburg-Halbschmal";
                font-size: 4vw;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                letter-spacing: normal;
              }
  
  
            }
  
            .text {
              margin:0;
              padding:0;
              font-family: "HTQ-Waldenburg-Halbschmal";
              font-size: 4vw;
              font-style: normal;
              font-weight: 400;
              line-height:  100%;
              letter-spacing: normal;
              margin-bottom: 6vw;
              position: relative;
            }
  
            .labels {
              display: flex;
              
              flex-direction: row;
              gap:2vw;
              margin-bottom: 4vw;
              position: relative;
              flex-wrap: wrap;
  
              .label {
                border:1px solid #111;
                border-radius: 30vw;
                font-family: "GTPressuraLCGVMono-Regular";
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                letter-spacing: -.03vw;
                text-transform: uppercase;
                font-size:3vw;
                padding:2vw;
                line-height: 80%;
                position: relative;
                top:unset;
                left:unset;
                opacity:1;

              }
            }
  
            &.black {
              background-color: #111;
              color:#E4E2CD;
              .title-title, .title-subtitle, .text { 
                color:#E4E2CD;
              }
              .label {
                color:#E4E2CD;
                border:1px solid #E4E2CD;
              }
            }
            
          }
  
        }
  
        .caption {
         
          bottom:.55vw;
          background:#111;
          color:#ECECEC;
          leading-trim: both;
          text-edge: cap;
          font-family: "GTPressuraLCGVMono-Regular";
          font-size: 2.54vw;
          font-style: normal;
          font-weight: 400;
          line-height:100%;
          letter-spacing:-.0254vw;
          text-transform: uppercase;
          max-width:calc(100% - 8vw );
          box-sizing: border-box;
          padding:1vw;
          
        }
  
      }
  
      &.block-table {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap:1.66vw;
        width:78.75vw;
        margin-left:auto;
        margin-right: auto;
        margin-top:2.22vw;
  
        margin-bottom: 5.55vw;
  
        .block-table-col {
          display: flex;
          width:50%;
          position: relative;
          align-items: center;
          justify-content: flex-start;
          border:1px solid #111;
          position: relative;
  
          .block-table-caption {
            background:#111;
            color: #CDD8E4;
            leading-trim: both;
            text-edge: cap;
            font-family: "GTPressuraLCGVMono-Regular";
            font-size: .833vw;
            font-style: normal;
            font-weight: 400;
            line-height:100%;
            letter-spacing:-.0083vw;
            text-transform: uppercase;
            box-sizing: border-box;
            padding-left:1.66vw;
            padding-top:.55vw;
            padding-bottom:.55vw;
          }
         
  
          .block-table-title {
            margin:0;
            padding:0;
            margin-top:1.11vw;
            margin-bottom:2.77vw;
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-FettSchmal";
            font-size: 2.77vw;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -0.0277vw;
            padding-left:1.66vw;
          }
  
          .block-table-text {
           
            margin-bottom:2.77vw;
            color: var(--Primary-Black, #111);
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-size:1.666vw;
            font-style: normal;
            font-weight: 400;
            line-height:  133.333%;
            letter-spacing: -.0166vw;
            padding-left:1.66vw;
            padding-right:2.77vw;
          }
  
          .buttons {
            display: flex;
            flex-direction: row;
            gap:1.2vw;
            margin-bottom: 1.11vw;
            .block-table-button {
              border:1px solid #111;
              border-radius: 13.88vw;
              background: none;
              box-sizing: border-box;
              padding:.98vw;
              padding-left:1.4vw;
              padding-right:1.4vw;
              pointer-events: all;
              cursor: pointer;
              transition: .3s all ease-out;
              &:hover {
                color:#D0E4CD;
                background:#111;
              }
  
              color:  #111;
              text-align: center;
              leading-trim: both;
              text-edge: cap;
              font-family: "HTQ-Waldenburg-FettSchmal";
              font-size: 1.11vw;
              font-style: normal;
              font-weight: 700;
              line-height:  125%;
            }
          }
          
        }
        &.two-col {
          
          .block-table-col {
            width: calc(50%);
           
          }
        }
      }
  
      &.image-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        gap:1.66vw;
        width:calc(100% - 8vw);
        margin-left:auto;
        margin-right: auto;
        margin-top:0vw;
        margin-bottom:5.55vw;
        flex-wrap: wrap;
  
        .block-image-row-col {
          display: flex;
          width:100%;
          position: relative;
          align-items: flex-start;
          justify-content: flex-start;
          flex-direction: column;
          margin-bottom:6.1vw;
  
          .image-wrapper {
            display: flex;
            position: relative;
            align-items: center;
            justify-content: flex-start;
            
            .image {
              width: 100%;
            }
    
            .caption {
              position: absolute;
              bottom:2vw;
              background:#111;
              color:#ECECEC;
              leading-trim: both;
              text-edge: cap;
              font-family: "GTPressuraLCGVMono-Regular";
              font-size: 2.54vw;
              font-style: normal;
              font-weight: 400;
              line-height:100%;
              letter-spacing:-.0254vw;
              text-transform: uppercase;
              max-width:calc(100% - 4vw );
              box-sizing: border-box;
              padding:1vw;
              margin-left:2vw;
            }
          }
          
          .block_image_row_col_table_block {
            margin-top:2vw;
            display: flex;
            position: relative;
            width:100%;
            align-items: center;
            justify-content: flex-start;
            border:1px solid #111;
            flex-direction: column;
            
            .block-table-text-wrapper {
              width:100%;
            }

            .block-table-caption {
              width: 100%;
              background:#111;
              color: #CDD8E4;
              leading-trim: both;
              text-edge: cap;
              font-family: "GTPressuraLCGVMono-Regular";
              font-size: 3vw;
              font-style: normal;
              font-weight: 400;
              line-height:100%;
              letter-spacing:-.03vw;
              text-transform: uppercase;
              box-sizing: border-box;
              padding-left:4vw;
              padding-top:2vw;
              padding-bottom:2vw;
            }
           
    
            .block-table-title {
              margin:0;
              padding:0;
              margin-top:6.1vw;
              margin-bottom:5vw;
              leading-trim: both;
              text-edge: cap;
              font-family: "HTQ-Waldenburg-FettSchmal";
              font-size: 10.17vw;
              font-style: normal;
              font-weight: 700;
              line-height: 100%;
              letter-spacing: -0.1vw;
              padding-left:4vw;
            }
    
            .block-table-text {
             
              margin-bottom:6.1vw;
              color: var(--Primary-Black, #111);
              font-family: "HTQ-Waldenburg-Halbschmal";
              font-size:5vw;
              font-style: normal;
              font-weight: 400;
              line-height:  133.333%;
              letter-spacing: -.05vw;
              padding-left:4vw;
              padding-right:4vw;

            }
    
            .buttons {
              display: flex;
              flex-direction: row;
              gap:1.2vw;
              margin-bottom: 1.11vw;
              .block-table-button {
                border:1px solid #111;
                border-radius: 13.88vw;
                background: none;
                box-sizing: border-box;
                padding:.98vw;
                padding-left:1.4vw;
                padding-right:1.4vw;
                pointer-events: all;
                cursor: pointer;
                transition: .3s all ease-out;
                &:hover {
                  color:#D0E4CD;
                  background:#111;
                }
    
                color:  #111;
                text-align: center;
                leading-trim: both;
                text-edge: cap;
                font-family: "HTQ-Waldenburg-FettSchmal";
                font-size: 1.11vw;
                font-style: normal;
                font-weight: 700;
                line-height:  125%;
              }
            }
          }
         
        }
  
       
      }
  
      &.block-scroll-table {
        position: relative;
        margin:0;
        padding:0;
        display: flex;
        width:100%;
  
        .scroll-arrows {
          display: flex;
          flex-direction: row;
          gap:2vw;
          position: absolute;
          top:-11.0vw;
          right:4vw;
          display: none;
          
          .arrow {
            margin:0;
            padding:0;
            color:#111;
            border-radius:20.35vw;
            border: 1px solid #111;
            background:transparent;
            padding:2vw;
            padding-left:3vw;
            padding-right:3vw;
            box-sizing: border-box;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
              width:3vw;
              padding:0;
              margin:0;
            }
  
            &.scroll-back  {
              rotate:(-180deg);
            }
  
            &.disable {
              opacity:.5;
            }
    
          }
        }
  
        .scrollable-area {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          gap:1.66vw;
          width:100%;
          padding-left:4vw;
          padding-right:2vw;
          position: relative;
          overflow-x:scroll;
          overflow-y: hidden;
          margin-left:auto;
          margin-right: auto;
          margin-top:2.22vw;
          margin-bottom: 8.33vw;
  
          -ms-overflow-style: none; /* for Internet Explorer, Edge */
          scrollbar-width: none; /* for Firefox */
          overflow-y: scroll;
          
          &::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
          }
  
          .block-scroll-table-col {
            display: flex;
            width:80vw;
            height: auto;
            box-sizing: border-box;
            flex-shrink: 0;
            position: relative;
            align-items: flex-start;
            justify-content: flex-start;
            border:1px solid #111;
            border-top:6.1vw solid #111;
          
  
            .block-scroll-table-title {
              margin:0;
              padding:0;
              margin-top:12.1vw;
              margin-bottom:4vw;
              width:60%;
              color:  #111;
              leading-trim: both;
              text-edge: cap;
              font-family: "HTQ-Waldenburg-FettSchmal";
              font-size:8.1vw;
              font-style: normal;
              font-weight: 700;
              line-height:  100%;
              letter-spacing: -.0277vw;
              padding-left:4vw;
              padding-right:4vw;
            }
  
            .block-scroll-table-text {
              margin:0;
              padding:0;
              margin-bottom:16vw;
              color:  #111;
              font-family: "HTQ-Waldenburg-Halbschmal";
              font-size:5vw;
              font-style: normal;
              font-weight: 400;
              line-height:  133.333%;
              letter-spacing: -.05vw;
              padding-left:4vw;
              padding-right:4vw;
            }
  
            .tags {
              display: flex;
              flex-direction: column;
              gap:1.2vw;
              position: relative;
              right:unset;
              left:4vw;
              top:6vw;
              margin-bottom:4vw;
              
              .block-scroll-table-button {
                border:none;
                border-radius: 10.1vw;
                background: none;
                box-sizing: border-box;
                padding:1.5vw;
                pointer-events:none;
                width:fit-content;
                padding-left:2vw;
                padding-right:2vw;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #111;
                font-family: "GTPressuraLCGVMono-Regular";
                font-size: 3vw;
                font-style: normal;
                font-weight: 400;
                line-height: 100%;
                letter-spacing:-.03vw;
                text-transform: uppercase;
              }
            }
            
          }
        }
        
      }

      &.stepped-block{
        position: relative;
        margin:0;
        padding:0;
        display: flex;
        width:100%;
        margin-bottom:20vw;
        // margin-top:80vw;
  
        .scrollable-area {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: flex-start;
          
          width:100%;
          padding-left:10.625vw;
          padding-right:2vw;
          position: relative;
          overflow-x:scroll;
          overflow-y: hidden;
          margin-left:auto;
          margin-right: auto;
          margin-top:2.22vw;
          margin-bottom: 8.33vw;
  
          -ms-overflow-style: none; /* for Internet Explorer, Edge */
          scrollbar-width: none; /* for Firefox */
          overflow-y: scroll;
          
          &::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
          }
  
          .stepped-block-col {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width:74vw;
            height: 60vw;
            box-sizing: border-box;
            flex-shrink: 0;
            position: relative;
            align-items: flex-start;
            border:1px solid #111;
            margin-right:-1px;

            &.col-0 { height: 30.5vw !important;}
            &.col-1 { height: 45.8vw !important;}
            &.col-2 { height: 61.1vw !important;}
            &.col-3 { height: 76.4vw !important;}
            &.col-4 { height: 91.7vw !important;}
            &.col-5 { height: 107vw !important;}
            &.col-6 { height: 122.3vw!important;}
            &.col-7 { height: 137.6vw!important;}
  
            .stepped-block-number {
              margin:0;
              padding:0;
              margin-top:4vw;
              width:60%;
              leading-trim: both;
              text-edge: cap;
              font-family: "GTPressuraLCGVMono-Regular";
              -webkit-text-stroke: 1px #111;
              text-stroke: 1px #111;
              opacity:.5;
              color: transparent;
              font-size: 10.1vw;
              font-style: normal;
              font-weight: 100;
              line-height:  100%;
              padding-left:4vw;
              padding-right:4vw;
            }
  
            .stepped-block-text {
              margin:0;
              padding:0;
              color: #111;
              font-size: 10.17vw;
              font-style: normal;
              font-weight: 700;
              line-height:100%;
              letter-spacing: -.1vw;
              font-family: "HTQ-Waldenburg-FettSchmal";
              padding-left:4vw;
              padding-right:4vw;
              margin-bottom:4vw;
            }
  
           
          }
        }
        
      }
  
      &.info-table {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top:2vw;
        margin-bottom:8.1vw;
  
        .info-table-title {
          color:  #111;
          leading-trim: both;
          text-edge: cap;
          font-family: "HTQ-Waldenburg-FettSchmal";
          font-size: 8.1vw;
          font-style: normal;
          font-weight: 700;
          line-height:  100%;
          letter-spacing: -.081vw;
          margin-bottom: 4vw;
        }
  
        .info-table-headings {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: flex-start;
          box-sizing: border-box;
          display: none;
  
          .heading {
            color:  #111;
            leading-trim: both;
            text-edge: cap;
            font-family: "GTPressuraLCGVMono-Regular";
            font-size:.833vw;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: -0.00833vw;
            text-transform: uppercase;
            padding-bottom:.55vw;
            padding-left:.833vw;
            box-sizing: border-box;
            flex-grow: 0;
            flex-shrink: 0;
  
            &.heading-0 {
              width:7.77vw;
            }
  
            &.heading-1 {
              width:15.694vw;
            }
  
            &.heading-2 {
              width:55vw;
            }
  
            &.heading-3 {
              width:15.694vw;
            }
          }
        }
  
        .info-table-table {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          box-sizing: border-box;
          display:none;  

          .info-table-row {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            border-top: 1px solid #111;
            box-sizing: border-box;
            height: 100%;
            margin-bottom:2.22vw;
  
            .col {
              padding-bottom:.55vw;
              padding-left:.833vw;
              padding-top:.833vw;
              box-sizing: border-box;
              border-left: 1px solid #111;
              box-sizing: border-box;
              align-self: stretch;
              flex-grow: 0;
              flex-shrink: 0;
  
              &.index {
                width:7.77vw;
                color:  #111;
                leading-trim: both;
                text-edge: cap;
                font-family: "HTQ-Waldenburg-Halbschmal";
                font-size: 1.388vw;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -.01388vw;
              }
    
              &.icon{
                width:15.694vw;
                box-sizing: border-box;
                height: 100%;
                img {
                  max-width:12vw;
                  max-height: 8vw;
                  object-fit: contain;
  
                }
              }
    
              &.text{
                width:55vw;
                color: var(--Primary-Black, #111);
                leading-trim: both;
                text-edge: cap;
                font-family: "HTQ-Waldenburg-Halbschmal";
                font-size: 1.388vw;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -.01388vw;
              }
  
              &.link {
                width:15.694vw;
                color: var(--Primary-Black, #111);
                leading-trim: both;
                text-edge: cap;
                font-family: "HTQ-Waldenburg-Halbschmal";
                font-size: 1.388vw;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -.01388vw;
                
              }
            }
  
          }
  
         
        }

        .info-table-mobile {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width:calc(100% - 8vw) ;

          .info-table-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            width:100%;
            margin-bottom:6.1vw;
            flex-shrink:0;
            flex-grow: 1;
            

            &.heading {
              margin:0;
              padding:0;
              
            }

            .col {
              display: flex;
              width:50%;
              align-items: flex-start;
              justify-content: flex-start;
              box-sizing: border-box;
              flex-shrink:0;
              flex-grow: 1;

              color:  #111;
              border-left:1px solid #111;
              border-top:1px solid #111;
              padding:3vw;

              align-self:stretch;

              &.heading {
                border:none;
                font-family: "GTPressuraLCGVMono-Regular";
                text-transform: uppercase;
                font-size: 3vw;
                margin:0;
                padding:0;
                padding-left:2vw;
                line-height: 100%;
                margin-bottom: 2vw;
              }

              &.index {
                font-family: "HTQ-Waldenburg-Halbschmal";
                font-size: 4vw;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -.04vw;
              }

              &.icon{
                width:50%;
                box-sizing: border-box;
                height: 100%;
                img {
                  max-width:32vw;
                  max-height: 14vw;
                  object-fit: contain;
                }
              }

              &.text{
                font-family: "HTQ-Waldenburg-Halbschmal";
                font-size: 4vw;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -.04vw;
              }
  
              &.link {
                font-family: "HTQ-Waldenburg-Halbschmal";
                font-size: 4vw;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: -.04vw;
                
              }

            }
          }

        }


      }

      &.pill-table {
        width:calc(100% - 8vw);
        .pills {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          box-sizing: border-box;
          width:100%;

          .pill {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            box-sizing: border-box;
            width:100%;
            border:none;
            margin-bottom:12vw;
           
            
            .row {
              width:100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              box-sizing: border-box;
              align-self: stretch;
              margin-bottom:6vw;
  
              .headings {
                width:100%;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                box-sizing: border-box;
                align-self: stretch;
                border-bottom:1px solid #111;
  
                .heading {
                  width:100%;
                  display: flex;
                  flex-direction: row;
                  align-items: flex-end;
                  justify-content: flex-start;
                  box-sizing: border-box;
                  align-self: stretch;
                  color:  #111;
                  font-family: "GTPressuraLCGVMono-Regular";
                  font-size: 3.05vw;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 100%;
                  letter-spacing: -0.03vw;
                  text-transform: uppercase;

                  padding-left:2.8vw;
                  padding-right:2.8vw;
                  padding-bottom:2vw;

                  &.half {
                    width:50%;
                  }
                }
  
                
              }

              .content {
                width:100%;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: flex-start;
                box-sizing: border-box;
                align-self: stretch;
                
  
                .col {
                  width:100%;
                  display: flex;
                  flex-direction: row;
                  align-items: flex-start;
                  justify-content: flex-start;
                  box-sizing: border-box;
                  align-self: stretch;
                  color:  #111;
                  font-family: "HTQ-Waldenburg-Halbschmal";
                  font-size: 4vw;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 175%;
                  text-decoration-line: underline;

                  padding-left:3vw;
                  padding-right:3vw;
                  padding-top:3vw;

                  .image {
                    height: 12.2vw;
                    width: auto;
                  }

                  &.half {
                    width:50%;
                  }
                }
  
                
              }
            }
  
          }
        }
        
      }

      &.pill-table-mobile {
        display: flex;
      }
      &.pill-table-desktop {
        display:none;
      }

      &.brain-block {
        margin:0;
        padding:0;
        width:100%;
        background: #050306;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-self: flex-start;
        margin-bottom:5.55vw;
  
        .title {
          color: var(--Secondary-3601-Light, #E4E2CD);
          font-family: "HTQ-Waldenburg-FettSchmal";
          font-size: 10.18vw;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
          letter-spacing: -.1vw;
          width:42.24vw;
          margin-left:4vw;
          margin-top:20.35vw;
        }
  
        .text-block {
          position: relative;
          width:81vw;
          margin-top:14.24vw;
          display: flex;
          align-self: flex-start;
          margin-left:13vw;
          margin-right: unset;
          box-sizing: border-box;

          .heading {
            position: absolute;
            left:0;
            top:1vw;
            color: #E4E2CD;
            font-family: "GTPressuraLCGVMono-Regular";
            font-size: 2.5vw;
            font-style: normal;
            font-weight: 400;
            line-height:  100%;
            letter-spacing: -0.025vw;
            text-transform: uppercase;
            opacity:.5;
          }
          .text {
            width: 100%;
            color: #E4E2CD;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-size: 6.1vw;
            font-style: normal;
            font-weight: 400;
            line-height: 133.33%;
            letter-spacing: -0.061vw;
            text-indent: 15vw;
            box-sizing: border-box;
          }
        }
  
        .image-mobile {
          display: flex;
          align-self: center;
          justify-self: center;
          margin-left:auto;
          margin-right:auto;
          width:91.86vw;
          height: auto;
          margin-top:14.24vw;
        }
        .image {
          display: none;
        }
  
        .subtext {
          color:  #E4E2CD;
          font-family: "HTQ-Waldenburg-Halbschmal";
          font-size: 5vw;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          letter-spacing: -0.05vw;
          display: flex;
          align-self: center;
          justify-self: center;
          margin-left:auto;
          margin-right:auto;
          width:90vw;
          height: auto;
          margin-top:4vw;
          margin-bottom:20vw;
        }
      }
      
      &.wave-block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width:calc(100% - 8vw);
        box-sizing: border-box;
        background:#fff;
        border-radius: 8.14vw;
  
        margin-top:8vw;
        margin-bottom:16vw;
  
        .title1 {
          -webkit-text-stroke: .5px #111;
          text-stroke: .5px #111;
          color: transparent;
          font-family: "HTQ-Waldenburg-FettSchmal";
          font-size: 10.18vw;
          font-style: normal;
          font-weight: 700;
          line-height:  100%;
          letter-spacing: -.1018vw;
          text-transform: uppercase;
          margin-top:6vw;
          margin-left:6vw;
        }
  
        .title2 {
          color: var(--Primary-Black, #111);
          font-family: "HTQ-Waldenburg-FettSchmal";
          font-size: 10.18vw;
          font-style: normal;
          font-weight: 700;
          line-height: 90%;
          letter-spacing: -.1018vw;
          margin-left:6vw;
          width:80%;
        }
  
        .wave-graph {
          display: none;
        }
        .wave-graph-mobile {
          display: flex;
          width: 79.6vw;
          display: flex;
          align-self: center;
          margin-top:8vw;
          margin-bottom:9vw;
        }
      }

      &.scrollable-image-row {
        margin:0;
        padding:0;
        display: flex;
        width:100%;
        box-sizing: border-box;
  
        margin-bottom: 30vw;
        margin-top:8vw;
  
        .scroll-container {
          overflow-x: scroll;
          width:100%;
          box-sizing: border-box;
          height: auto;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap:4vw;
          padding-left:4vw;
          padding-right:4vw;
  
          .image-block {
            display: flex;
            height: 101.78vw;
            box-sizing: border-box;
            position: relative;
            user-select: none;
            pointer-events: none;
  
            .caption {
              position: absolute;
              bottom:2vw;
              background:#111;
              color:#ECECEC;
              leading-trim: both;
              text-edge: cap;
              font-family: "GTPressuraLCGVMono-Regular";
              font-size: 2.54vw;
              font-style: normal;
              font-weight: 400;
              line-height:100%;
              letter-spacing:-.0254vw;
              text-transform: uppercase;
              max-width:calc(100% - 4vw );
              box-sizing: border-box;
              padding:1vw;
              margin-left:2vw;
            }
          }
  
          .drag-icon {
            width:11.11vw;
            user-select: none;
            pointer-events: none;
            display: none;
          }
        }
      }
     
      &.image-text-block {
        display: flex;
        flex-direction: column;
        width:calc(100% - 8vw);
        gap:14vw;
        box-sizing: border-box;
        margin-bottom:30vw;
  
        .text-block {
          display: flex;
          width:100%;
          box-sizing: border-box;
          position: relative;
        
          padding-left:11vw;
  
          .text {
            text-indent: 12vw;
            position: relative;
            .label {
              position: absolute;
              top:1.6vw; 
              left:-10.8vw;
              color: var(--Primary-Black, #111);
              font-family: "GTPressuraLCGVMono-Regular";
              font-size: 2.5vw;
              font-style: normal;
              font-weight: 400;
              line-height: 100%;
              letter-spacing: -.25vw;
              text-transform: uppercase;
              opacity:.5;
             
            }
            
          }
  
         
        }


        .image-wrapper {
          display: flex;
          position: relative;
          align-items: center;
          justify-content: flex-start;

          width:100%;
          padding:0;
          margin:0;
          
          .image {
            width: 100%;
          }
  
          .caption {
            position: absolute;
            bottom:2vw;
            background:#111;
            color:#ECECEC;
            leading-trim: both;
            text-edge: cap;
            font-family: "GTPressuraLCGVMono-Regular";
            font-size: 2.54vw;
            font-style: normal;
            font-weight: 400;
            line-height:100%;
            letter-spacing:-.0254vw;
            text-transform: uppercase;
            max-width:calc(100% - 4vw );
            box-sizing: border-box;
            padding:1vw;
            margin-left:2vw;
          }
        }
  
  
        &.reverse {
          flex-direction: column;

          .text-block {
            padding-left:0;
            padding-right:12vw;
            align-self: flex-start;
            justify-self: flex-start;
          }
  
          .image-wrapper {
            padding-top: 0;
          }
  
        }
      }
      

      &.image-caption {

        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-start;
        width:calc(100% - 8vw);
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
  
        align-self:center;
        margin-bottom:30vw;
  
        .image {
          width: 100%;
        }
  
        .caption {
          position: absolute;
          bottom:2vw;
          background:#111;
          color:#ECECEC;
          leading-trim: both;
          text-edge: cap;
          font-family: "GTPressuraLCGVMono-Regular";
          font-size: 2.54vw;
          font-style: normal;
          font-weight: 400;
          line-height:100%;
          letter-spacing:-.0254vw;
          text-transform: uppercase;
          max-width:calc(100% - 4vw );
          box-sizing: border-box;
          padding:1vw;
          margin-left:2vw;
        }
      
  
      }
     
    }

  }


 
}


