.act {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: auto;
  width:calc(100%);
  min-height: 100%;
  padding:0;
  margin:0;
  position: relative;
  background-color:#D0E4CD; 
  background-image:url("../../assets/images/noise.png"); 
  background-repeat: repeat; 
  background-size: auto;

  .title {
    color: #111;
    leading-trim: both;
    text-edge: cap;
    font-family: "HTQ-Waldenburg-FettSchmal";
    font-size:19.167vw;
    font-style: normal;
    font-weight: 700;
    line-height:78.261%;
    letter-spacing: -0.575vw;
    text-transform: uppercase;
    margin-left: 2.77vw;

    .title-icon {
      width:13.68vw;
    }
  }

  .block {
    margin:0;
    padding:0;
    color:  #111;
    leading-trim: both;
    text-edge: cap;
    font-family: "HTQ-Waldenburg-Halbschmal";
    font-size: 2.22vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.022vw;
    width: calc(100% - 5.55vw);
    margin-left: 2.77vw;
    box-sizing: border-box;

    &.indent-block {
     
      padding-left:7.9vw;
      padding-right:7.9vw;
      text-indent: 8.4vw;
      margin-bottom:5.55vw;
    }

    &.inset {
      text-indent: 8.4vw;
      padding-left:23.6vw;
      padding-right:10.62vw;
    }

    &.titles{
      margin-bottom:7.22vw;
      margin-top:8.33vw;
      box-sizing: border-box;
      overflow:hidden;
      .block-title {
        color: #111;
        leading-trim: both;
        text-edge: cap;
        font-family: "HTQ-Waldenburg-FettSchmal";
        font-size: 13.88vw;
        font-style: normal;
        font-weight: 700;
        line-height:76%;
        letter-spacing: -.277vw;
        text-transform: uppercase;
        margin-bottom:1.11vw;
        width:calc(100% - 7.9vw);
        box-sizing: border-box;
        
        &.left {
          text-align: left;
        }

        &.right {
          text-align: right;
        }

        &.indent-1 {
          margin-left:15vw;
        }

        &.indent-2 {
          margin-left:8vw;
        }

      }

    }

    &.block-table {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: center;
      box-sizing: border-box;
      border:1px solid #111;
      border-top:2vw solid #111;
      // height: 100%;
      margin-bottom:5.55vw;

      .block-table-col {
        box-sizing: border-box;
        padding:1.11vw;
        width:25%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        // height: 100%;
        border-left:1px solid #111;
        position: relative;

        &:first-of-type  {
          border-left:none;
        }

        .block-table-title {
          margin:0;
          padding:0;
          margin-top:1.11vw;
          margin-bottom:2.77vw;
          leading-trim: both;
          text-edge: cap;
          font-family: "HTQ-Waldenburg-FettSchmal";
          font-size: 2.77vw;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
          letter-spacing: -0.0277vw;
          text-transform: uppercase;
        }

        .block-table-text {
         
          margin-bottom:2.77vw;
          color: var(--Primary-Black, #111);
          leading-trim: both;
          text-edge: cap;
          font-family: "HTQ-Waldenburg-Halbschmal";
          font-size:1.666vw;
          font-style: normal;
          font-weight: 400;
          line-height:  133.333%;
          letter-spacing: -.0166vw;
        }

        .buttons {
          display: flex;
          flex-direction: row;
          gap:1.2vw;
          margin-bottom: 1.11vw;
          .block-table-button {
            border:1px solid #111;
            border-radius: 13.88vw;
            background: none;
            box-sizing: border-box;
            padding:.98vw;
            padding-left:1.4vw;
            padding-right:1.4vw;
            pointer-events: all;
            cursor: pointer;
            transition: .3s all ease-out;
            &:hover {
              color:#D0E4CD;
              background:#111;
            }

            color:  #111;
            text-align: center;
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-FettSchmal";
            font-size: 1.11vw;
            font-style: normal;
            font-weight: 700;
            line-height:  125%;
          }
        }
        
      }
      &.two-col {
        .block-table-col {
          width: 50%;
          flex-grow: 0;
          flex-shrink: 1;
         
        }
      }

      &.single {
        width:calc(50% - 2.7vw);
        .block-table-col {
          width: 100%;
          flex-grow: 0;
          flex-shrink: 1;
         
        }
      }
    }
   
  }


  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
    
    .title {
      align-self:flex-start;
      width:calc(100% - 8vw);
      word-wrap: break-word;
      // overflow-wrap:break-word;
      // word-break: break-all;
      font-size:30.5vw;
      line-height:78.261%;
      letter-spacing: -0.575vw;
      margin-left: 4vw;
      margin-top:55vw;
      .title-icon {
        width:21.88vw;
      }
    }

    .block {
     
      font-size: 6.1vw;
      font-style: normal;
      font-weight: 400;
      line-height: 133.33%;
      letter-spacing: -0.061vw;
      width: calc(100% - 8vw);
      margin-left: 4vw;
      box-sizing: border-box;
  
      &.indent-block {
       
        padding-left:11.2vw;
        padding-right:4vw;
        text-indent: 8.4vw;
        margin-bottom:14.2vw;
      }
  
      &.inset {
        padding-left:4vw;
        padding-right:4vw;
        text-indent: 8.4vw;
        margin-bottom:14.2vw;
      }
  
      &.titles{
        margin-bottom:10.2vw;
        margin-top:16.2vw;
        box-sizing: border-box;
        overflow:hidden;
        .block-title {
          color: #111;
          leading-trim: both;
          text-edge: cap;
          font-family: "HTQ-Waldenburg-FettSchmal";
          font-size: 14.2vw;
          font-style: normal;
          font-weight: 700;
          line-height:76%;
          letter-spacing: -.142vw;
          text-transform: uppercase;
          margin-bottom:2vw;
          width:calc(100% - 8vw);
          box-sizing: border-box;
          
          &.left {
            text-align: left;
          }
  
          &.right {
            text-align: right;
          }
  
          &.indent-1 {
            margin-left:15vw;
          }
  
          &.indent-2 {
            margin-left:4vw;
          }
  
        }
  
      }
  
      &.block-table {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        box-sizing: border-box;
        border:none;
        // height: 100%;
        
  
        .block-table-col {
          box-sizing: border-box;
          padding:4vw;
          width:100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          // height: 100%;
          
          border:1px solid #111;
          border-top:6.1vw solid #111;
          position: relative;
          margin-bottom:5.55vw;
          
          &:first-of-type  {
            border:1px solid #111;
            border-top:6.1vw solid #111;
          }
  
  
          .block-table-title {
            margin:0;
            padding:0;
            margin-top:4.1vw;
            margin-bottom:6.1vw;
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-FettSchmal";
            font-size: 8.1vw;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: -0.082vw;
            text-transform: uppercase;
          }
  
          .block-table-text {
           
            margin-bottom:8.1vw;
            color: var(--Primary-Black, #111);
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-size:5vw;
            font-style: normal;
            font-weight: 400;
            line-height:  133.333%;
            letter-spacing: -.05vw;
          }
  
          .buttons {
            display: flex;
            flex-direction: row;
            gap:4vw;
            margin-bottom: 0vw;
            flex-wrap: wrap;
            .block-table-button {
              border:1px solid #111;
              border-radius: 20vw;
              background: none;
              box-sizing: border-box;
              height: 11.7vw;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              padding:4vw;
              padding-left:6vw;
              padding-right:6vw;
              pointer-events: all;
              cursor: pointer;
              transition: .3s all ease-out;
              &:hover {
                color:#D0E4CD;
                background:#111;
              }
  
              color:  #111;
              text-align: center;
              leading-trim: both;
              text-edge: cap;
              font-family: "HTQ-Waldenburg-FettSchmal";
              font-size: 5vw;
              font-style: normal;
              font-weight: 700;
              line-height:  125%;
            }
          }
          
        }
        &.two-col {
          .block-table-col {
            width: 100%;
          }
        }

        &.single {
          width:calc(100% - 8vw);
          .block-table-col {
            width: 100%;
          }
        }
      }
     
    }


  }


 
}


