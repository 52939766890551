.faq {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width:calc(100%);
  min-height: 100%;
  padding:0;
  margin:0;
  position: relative;
  background-color:#E4CDDF; 
  background-image:url("../../assets/images/noise.png"); 
  background-repeat: repeat; 
  background-size: auto;

  .title {
    color: #111;
    leading-trim: both;
    text-edge: cap;
    font-family: "HTQ-Waldenburg-FettSchmal";
    font-size:19.167vw;
    font-style: normal;
    font-weight: 700;
    line-height:78.261%;
    letter-spacing: -0.575vw;
    text-transform: uppercase;
    margin-left: 2.77vw;
    padding-bottom:0;
    margin-bottom:11.11vw;
    // width: min-content;
    

    .title-icon {
      width:13.68vw;
    }
  }

  .mobile-title {
    display: none;
  }

  .sub-title {
    margin:0;
    padding:0;
    color: #111;
    leading-trim: both;
    text-edge: cap;
    font-family: "HTQ-Waldenburg-Halbschmal";
    font-size: 2.22vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.0222vw;
    width:78.33vw;
    text-indent: 8.4vw;
    margin-bottom:11.11vw;
  }

  .faqs {
    width:71.8vw;
    margin-bottom:24vw;

    .faq-set{
      display: flex;
      flex-direction: column;
      width:100%;
      

      .faq-question {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
       
        position: relative;

        margin-bottom:1.11vw;
        svg {
          width:3.88vw;
          margin-right:.55vw;
        }

        .text {
          margin:0;
          padding:0;
          color:#111;
          leading-trim: both;
          text-edge: cap;
          font-family: "HTQ-Waldenburg-FettSchmal";
          font-size: 1.66vw;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
          text-transform: uppercase;

          border:1px solid #111;
          padding-top:1.8vw;
          padding-bottom:1.6vw;
          padding-right:2.77vw;
          padding-left:2.77vw;
          box-sizing: border-box;
          border-radius: 2.77vw 2.77vw 2.77vw 0;
          max-width:42vw;
          width: fit-content;
        }

      }

      .faq-answer {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        align-self: flex-end;
        justify-self: flex-end;
        position: relative;
        margin-bottom:1.11vw;

        svg {
          width:3.88vw;
          margin-left:.55vw;
        }

        .text {
          margin:0;
          padding:0;
          color:#111;
          leading-trim: both;
          text-edge: cap;
          font-family: "HTQ-Waldenburg-Halbschmal";
          font-size: 1.66vw;
          font-style: normal;
          font-weight: 400;
          line-height: 133.333%;
          letter-spacing: -0.0166vw;
          
          background: white;
          text-align: right;
          // border:1px solid #111;
          padding-top:1.8vw;
          padding-bottom:1.6vw;
          padding-right:2.77vw;
          padding-left:2.77vw;
          box-sizing: border-box;
          border-radius:  2.77vw 2.77vw 0 2.77vw;
          max-width:56vw;
          width: fit-content;
        }

      }
    }
  }

  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
    .title{
      display: none;
    }
    .mobile-title {
      display: block;
      color: #111;
      leading-trim: both;
      text-edge: cap;
      font-family: "HTQ-Waldenburg-FettSchmal";
      font-size:19.167vw;
      font-style: normal;
      font-weight: 700;
      line-height:78.261%;
      letter-spacing: -0.575vw;
      text-transform: uppercase;
      margin-left: 2.77vw;
      padding-bottom:0;
      margin-bottom:11.11vw;
      align-self:flex-start;
      width:calc(100% - 8vw);
      // width: min-content;
      word-wrap: break-word;
      
      
      font-size:30.5vw;
      line-height:78.261%;
      letter-spacing: -0.575vw;
      margin-left: 4vw;
      margin-top:55vw;
      .title-icon {
        width:21.88vw;
      }
    }

    .sub-title {

      font-size:6.1vw;
      line-height: 133.33%;
      letter-spacing: -0.061vw;
    }


    .faqs {
      width:calc(100% - 8vw);
      margin-bottom:24vw;
  
      .faq-set{
        display: flex;
        flex-direction: column;
        width:100%;
        
  
        .faq-question {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
         
          position: relative;
  
          margin-bottom:6.1vw;
          svg {
            width:14.3vw;
            margin-right:2vw;
          }
  
          .text {
            margin:0;
            padding:0;
            color:#111;
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-FettSchmal";
            font-size: 6.1vw;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
  
            border:1px solid #111;
            padding-top:6vw;
            padding-bottom:6vw;
            padding-right:6vw;
            padding-left:6vw;
            box-sizing: border-box;
            border-radius: 10vw 10vw 10vw 0;
            max-width:100%;
            width: fit-content;
          }
  
        }
  
        .faq-answer {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          align-self: flex-end;
          justify-self: flex-end;
          position: relative;
          margin-bottom:6.1vw;
  
          svg {
            width:14.3vw;
            margin-left:2vw;
          }
  
          .text {
            margin:0;
            padding:0;
            color:#111;
            leading-trim: both;
            text-edge: cap;
            font-family: "HTQ-Waldenburg-Halbschmal";
            font-size: 5vw;
            font-style: normal;
            font-weight: 400;
            line-height: 133.333%;
            letter-spacing: -0.0166vw;
            
            background: white;
            text-align: left;
            // border:1px solid #111;
            padding-top:6vw;
            padding-bottom:6vw;
            padding-right:6vw;
            padding-left:6vw;
            box-sizing: border-box;
            border-radius:  10vw 10vw 0 10vw;
            max-width:100%;
            width: fit-content;
          }
  
        }
      }
    }

  }


 
}


