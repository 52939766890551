.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 6.4vw;
  min-height: 104px;
  width:100%;
  padding:0;
  margin:0;
  position: fixed;
  z-index: 100;
  top:0;
  left:0;
  pointer-events: none;

  .header-left {
    margin-left:2.77vw;
  }

  .header-right {
    margin-right:2.77vw;
    .home-button {
      padding:0;
      margin:0;
      background:none;
      border:none;
      cursor: pointer;
      font-family: "HTQ-Waldenburg-FettSchmal";
      font-feature-settings: "case" on;
      font-size: 1.11vw;
      font-style: normal;
      font-weight: 700;
      line-height:  100%;
      text-transform: uppercase;
      text-decoration: none;
      pointer-events: all;
    }
  }

  .title {
    font-family: "HTQ-Waldenburg-FettSchmal";
    font-size: 9.166vw;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -.09166vw;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {

    position: fixed;
    align-items: center;
    height: auto;
    min-height: unset;
    margin-top:6vw;
    
    .header-left {
      margin-left:4vw;

    }

    .header-right {
      margin-right:4vw;
      .home-button {
        padding:0;
        margin:0;
        background:none;
        border:none;
        cursor: pointer;
        font-family: "HTQ-Waldenburg-FettSchmal";
        font-size: 5vw;
        color:#111 !important;

      }
    }
  }


 
}


