.imprint {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  width:calc(100%);
  min-height: 100%;
  padding:0;
  margin:0;
  position: relative;
  background-color:#CFC9C9; 
  background-image:url("../../assets/images/noise.png"); 
  background-repeat: repeat; 
  background-size: auto;

  .title {
    align-self:flex-start;
    color: #111;
    leading-trim: both;
    text-edge: cap;
    font-family: "HTQ-Waldenburg-FettSchmal";
    font-size:19.167vw;
    font-style: normal;
    font-weight: 700;
    line-height:78.261%;
    letter-spacing: -0.575vw;
    text-transform: uppercase;
    margin-left: 2.77vw;
    padding-bottom:0;
    margin-bottom:11.11vw;
    // width: min-content;
    

    .title-icon {
      width:13.68vw;
    }
  }

  .mobile-title {
    display: none;
  }

  .content {
    align-self: flex-start;
    margin:0;
    padding:0;
    color: #111;
    font-family: "HTQ-Waldenburg-Halbschmal";
    font-size: 2.22vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.0222vw;
    width:78.33vw;
    margin-left: 5.55vw;
    margin-bottom:11.11vw;

    h2 {
      font-weight: 700;
    }
  }


  @media only screen and (max-width: 1023px) {
    
  }

  @media only screen and (max-width: 767px) {
    .title{
      display: none;
    }
    .mobile-title {
      display: block;
      color: #111;
      leading-trim: both;
      text-edge: cap;
      font-family: "HTQ-Waldenburg-FettSchmal";
      font-size:19.167vw;
      font-style: normal;
      font-weight: 700;
      line-height:78.261%;
      letter-spacing: -0.575vw;
      text-transform: uppercase;
      margin-left: 2.77vw;
      padding-bottom:0;
      margin-bottom:11.11vw;
      align-self:flex-start;
      width:calc(100% - 8vw);
      // width: min-content;
      word-wrap: break-word;
      
      
      font-size:30.5vw;
      line-height:78.261%;
      letter-spacing: -0.575vw;
      margin-left: 4vw;
      margin-top:55vw;
      .title-icon {
        width:21.88vw;
      }
    }

    .content {

      font-size:6.1vw;
      line-height: 133.33%;
      letter-spacing: -0.061vw;
    }



  }


 
}


